import React from "react";
import type { WidgetTrack } from "@widget/types";
import { StreamLogContextType } from "@widget/types";
import { useParams } from "react-router-dom";
import QueuedWidget from "@widget/formats/QueuedWidget";
import { dzrLink } from "@components/ExternalLink";
import useQueryError from "@widget/hooks/useQueryError";
import { useOptions } from "@widget/queryParams";
import getUTMParams from "@widget/utils/getUTMParams";
import type { TopTracksApiResponse } from "./types";
import useArtistTopTracks from "./useArtistTopTracks";

type RouteParams = {
  artistId: string;
};

const TopTracksWidget: React.FC = () => {
  const { artistId = "" } = useParams<RouteParams>();
  const {
    isLoading,
    data: topTracks,
    isError,
    error,
  } = useArtistTopTracks(artistId);
  useQueryError({ isError, error });
  const { app_id } = useOptions();

  const utm = getUTMParams({
    appId: app_id,
    contextId: artistId,
    contextType: "artist-top-track",
  });

  const tracklist = getTracklist(topTracks, utm);
  return <QueuedWidget tracklist={tracklist} isLoading={isLoading} />;
};

export default TopTracksWidget;

export const getTracklist = (
  topTracks?: TopTracksApiResponse,
  utm?: string
): WidgetTrack[] | undefined => {
  if (!topTracks || !topTracks.data?.included) return undefined;

  const topTracksMeta = topTracks.data.attributes;
  const topTracksId = topTracks.data?.id as string;

  return topTracks.data.included.map((track) => {
    const trackMeta = track.attributes;

    return {
      type: "track",
      id: track.id,
      cover: trackMeta.image.medium,
      readable: true,
      title: trackMeta.title,
      audioUrl: "",
      contextType: StreamLogContextType.ArtistTopTracks,
      contextId: topTracksId,
      playerMeta: {
        initial: {
          title: {
            label: topTracksMeta.artistName,
            redirectUrl: dzrLink("artist-top-track", trackMeta.artistId, utm),
          },
          cover: trackMeta.image.large,
        },
        touched: {
          title: {
            label: trackMeta.title,
            redirectUrl: dzrLink("artist-top-track", trackMeta.artistId, utm),
          },
          subtitle: {
            label: trackMeta.artistName,
            redirectUrl: dzrLink("artist", trackMeta.artistId, utm),
          },
          cover: trackMeta.image.large,
        },
      },
      tracklistItemMeta: {
        title: {
          label: trackMeta.title,
        },
        subtitle: {
          label: trackMeta.artistName,
          redirectUrl: dzrLink("artist", trackMeta.artistId, utm),
        },
      },
      sharingMenuMeta: {
        redirectUrl: dzrLink("track", track.id, utm),
      },
    };
  });
};
