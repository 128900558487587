import { useState, useRef, useEffect } from "react";

/**
 * We want to show the loading spinner only after some time has elapsed, to
 * avoid showing it very briefly when you have a good network connection
 */
const DELAY = 300;

function useCentralButtonLoading(isLoading: boolean, isPlaying: boolean) {
  const [showLoading, setShowLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isLoading && isPlaying) {
      timeoutRef.current = setTimeout(() => setShowLoading(true), DELAY);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setShowLoading(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isLoading, isPlaying]);

  return showLoading;
}

export default useCentralButtonLoading;
