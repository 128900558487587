import React from "react";
import type { WidgetTrack } from "@widget/types";
import { StreamLogContextType } from "@widget/types";
import { useParams } from "react-router-dom";
import QueuedWidget from "@widget/formats/QueuedWidget";
import { dzrLink } from "@components/ExternalLink";
import useQueryError from "@widget/hooks/useQueryError";
import { useOptions } from "@widget/queryParams";
import getUTMParams from "@widget/utils/getUTMParams";
import useCollectionApi from "../collection/useCollectionApi";
import type { PodcastApiResponse } from "./types";

type RouteParams = {
  podcastId: string;
};

const PodcastWidget: React.FC = () => {
  const { podcastId = "" } = useParams<RouteParams>();
  const {
    isLoading,
    data: podcast,
    isError,
    error,
  } = useCollectionApi<PodcastApiResponse>({
    name: "podcast",
    id: podcastId,
  });
  useQueryError({ isError, error });
  const { app_id } = useOptions();
  const utm = getUTMParams({
    appId: app_id,
    contextId: podcastId,
    contextType: "show",
  });

  const tracklist = getTracklist(podcast, utm);

  return (
    <QueuedWidget tracklist={tracklist} isLoading={isLoading} allowSeekJumps />
  );
};

export default PodcastWidget;

export const getTracklist = (
  podcast?: PodcastApiResponse,
  utm?: string
): WidgetTrack[] | undefined => {
  if (!podcast || !podcast.data?.included) return undefined;

  const podcastMeta = podcast.data.attributes;

  return podcast.data.included.map((episode) => {
    const episodeMeta = episode.attributes;
    const podcastId = podcast.data?.id as string;

    return {
      type: "episode",
      id: episode.id,
      cover: episodeMeta.image.medium,
      readable: true,
      title: episodeMeta.title,
      audioUrl: episodeMeta.url,
      contextType: StreamLogContextType.Podcast,
      contextId: podcastId,
      playerMeta: {
        initial: {
          title: {
            label: podcastMeta.title,
            redirectUrl: dzrLink("show", podcastId, utm),
          },
          cover: podcastMeta.image.large,
        },
        touched: {
          title: {
            label: podcastMeta.title,
            redirectUrl: dzrLink("show", podcastId, utm),
          },
          subtitle: {
            label: episodeMeta.title,
            redirectUrl: dzrLink("episode", episode.id, utm),
          },
          cover: episodeMeta.image.large,
        },
      },
      tracklistItemMeta: {
        title: {
          label: episodeMeta.title,
        },
      },
      sharingMenuMeta: {
        redirectUrl: dzrLink("episode", episode.id, utm),
      },
    };
  });
};
