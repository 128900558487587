import React, { useRef } from "react";
import styled from "styled-components";

import { useColor } from "@widget/player/contexts/ColorProvider";
import TrackActive from "./TrackActive";
import TrackHandle from "./TrackHandle";
import useProgressBar from "../hooks/useProgressBar";
import type { ProgressBarRef, ProgressBarProps } from "../types";
import TrackBuffer from "./TrackBuffer";

function ProgressBar(props: ProgressBarProps) {
  const { visible, onSpacePress } = props;
  const BAR_HEIGHT = 6;
  const pBarRef = useRef() as ProgressBarRef;
  const {
    $percent,
    onMouseDown,
    onTouchStart,
    onKeyDown,
    trackHandleVisible,
    onMouseEnter,
    onMouseLeave,
    buffer,
  } = useProgressBar(pBarRef, props);

  const { color } = useColor();

  return (
    <ProgressBarContainer
      ref={pBarRef}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $barHeight={BAR_HEIGHT}
      $visible={visible}
      data-testid="widget-player-seekbar"
    >
      <TrackBuffer $percent={buffer.width} $left={buffer.origin} />
      <TrackActive $percent={$percent} color={color} />
      <TrackHandle
        $height={BAR_HEIGHT}
        $percent={$percent}
        $visible={trackHandleVisible}
        $pBarVisible={visible}
        onKeyDown={(e) => {
          if (e.keyCode === 32) {
            onSpacePress(e);
          } else {
            onKeyDown(e);
          }
        }}
      />
    </ProgressBarContainer>
  );
}

export default ProgressBar;

type ProgressBarContainerProps = {
  $barHeight: number;
  $visible: boolean;
};

const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  position: absolute;
  bottom: ${({ $visible }) => ($visible ? 0 : -6)}px;
  /*! @noflip */
  left: 0;
  width: 100%;
  height: ${({ $barHeight }) => $barHeight}px;
  cursor: pointer;
  background-color: #4f5b61;
  transition: bottom 0.5s ease;
`;
