import { useQuery } from "@tanstack/react-query";
import type { WidgetTrack } from "@widget/types";
import type { TrackPreviewApiResponse, TrackApiResponse } from "./types";
import api from "../api";

async function queryPreviewUrl(
  key: string,
  trackId?: WidgetTrack["id"]
): Promise<string | undefined> {
  if (!trackId) return "";
  /**
   * TODO: Remove this call when /track/{trackId}/previewUrl gets smarter!
   * We fetch the track information because we need to get the trackId fallback
   * if necessary (if we use track.id directly, many previewUrl fetches will
   * result in a 404 because it isn't smart enough to find available fallbacks
   * when it's needed)
   */
  const response = await api.get<TrackApiResponse>(`/track/${trackId}`);
  if (!response) return undefined;

  const trackData = response.data;
  const trackIdFallbacked = trackData.data?.id;

  /**
   * Then we fetch previewUrl for the (eventually) fallbacked trackId
   */
  const { data } = await api.get<TrackPreviewApiResponse>(
    `/track/${trackIdFallbacked}/previewUrl`
  );

  return data.data?.attributes?.url;
}

type UseTrackPreviewProps = {
  track?: WidgetTrack;
  isPlaying: boolean;
};
type PreviewQueryKey = [string, string | undefined];
function useTrackPreview({ track, isPlaying }: UseTrackPreviewProps) {
  return useQuery<string | undefined, unknown, string, PreviewQueryKey>(
    ["track-preview", track?.id],
    ({ queryKey }) => queryPreviewUrl(queryKey[0], queryKey[1] as string),
    {
      enabled: Boolean(track) && track?.type === "track" && isPlaying,
    }
  );
}

export default useTrackPreview;
