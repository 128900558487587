import React from "react";
import type { WidgetTrack } from "@widget/types";
import { StreamLogContextType } from "@widget/types";
import { useParams } from "react-router-dom";
import QueuedWidget from "@widget/formats/QueuedWidget";
import { dzrLink } from "@components/ExternalLink";
import useQueryError from "@widget/hooks/useQueryError";
import { useOptions } from "@widget/queryParams";
import getUTMParams from "@widget/utils/getUTMParams";
import useCollectionApi from "../collection/useCollectionApi";
import type { PlaylistApiResponse } from "./types";

const PlaylistWidget: React.FC = () => {
  const { playlistId } = useParams();

  const {
    isLoading,
    data: playlist,
    isError,
    error,
  } = useCollectionApi<PlaylistApiResponse>({
    name: "playlist",
    id: playlistId,
  });
  useQueryError({ isError, error });
  const { app_id } = useOptions();
  const utm = getUTMParams({
    appId: app_id,
    contextId: playlistId || "",
    contextType: "playlist",
  });

  const tracklist = getTracklist(playlist, utm);

  return <QueuedWidget tracklist={tracklist} isLoading={isLoading} />;
};

export default PlaylistWidget;

export const getTracklist = (
  playlist?: PlaylistApiResponse,
  utm?: string
): WidgetTrack[] | undefined => {
  if (!playlist || !playlist.data?.included) return undefined;

  const playlistMeta = playlist.data.attributes;
  const playlistId = playlist.data?.id as string;

  return playlist.data.included.map((track) => {
    const trackMeta = track.attributes;

    return {
      type: "track",
      id: track.id,
      cover: trackMeta.image.medium,
      readable: true,
      title: trackMeta.title,
      audioUrl: "",
      contextType: StreamLogContextType.Playlist,
      contextId: playlistId,
      playerMeta: {
        initial: {
          title: {
            label: playlistMeta.name,
            redirectUrl: dzrLink("playlist", playlistId, utm),
          },
          cover: playlistMeta.image.large,
        },
        touched: {
          title: {
            label: trackMeta.title,
            redirectUrl: dzrLink("playlist", playlistId, utm),
          },
          subtitle: {
            label: trackMeta.artistName,
            redirectUrl: dzrLink("artist", trackMeta.artistId, utm),
          },
          cover: trackMeta.image.large,
        },
      },
      tracklistItemMeta: {
        title: {
          label: trackMeta.title,
        },
        subtitle: {
          label: trackMeta.artistName,
          redirectUrl: dzrLink("artist", trackMeta.artistId, utm),
        },
      },
      sharingMenuMeta: {
        redirectUrl: dzrLink("track", track.id, utm),
      },
    };
  });
};
