import styled from "styled-components";
import React from "react";

type TrackBufferProps = {
  $percent: number;
  $left: number;
};

/**
 * Note: we use a style inline object instead of styled template string, because
 * the values frequently changes ! This is intentional, and not a mistake.
 *
 * When using only styled component, it raises a warning in console:
 *
 * > Consider using the attrs method, together with a style object for frequently changed styles.
 */
const TrackBuffer: React.FC<TrackBufferProps> = ({ $percent, $left }) => {
  return (
    <Container
      style={{
        width: `${$percent}%`,
        left: `${$left}%`,
      }}
    />
  );
};

export default TrackBuffer;

const Container = styled.div`
  height: 100%;
  background-color: #64808e;
  position: absolute;
  top: 0;
`;
