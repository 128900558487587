import styled from "styled-components";

const ShareLogoContainer = styled.div<{ size?: string }>`
  width: ${({ size = "25px" }) => size};
  height: ${({ size = "25px" }) => size};
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ShareLogoContainer;
