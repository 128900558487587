/* eslint-disable react/display-name */
import React from "react";
import { useSizeMeasurer } from "@widget/components/SizeMeasurerProvider";
import styled from "styled-components";
import type { PlayerFormat } from "../types";

const HEIGHT_BREAKPOINT = 225;

type Props = {
  $format: PlayerFormat;
};

const PlayerCoverDimmer = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const playerSize = useSizeMeasurer();
    const isSmall = playerSize ? playerSize.height < HEIGHT_BREAKPOINT : false;
    return <LinearGradient {...props} $isSmall={isSmall} ref={ref} />;
  }
);


export default PlayerCoverDimmer;

type LinearGradientProps = {
  $isSmall: boolean;
} & Props;

const LinearGradient = styled.div<LinearGradientProps>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(2, 0, 36, 0.2);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, ${({ $isSmall }) => ($isSmall ? 0.25 : 0.05)}) 100%
  );
`;
