import { useRouter } from "next/router";
import { useLocation } from "react-router-dom";

const useLocationSearch = () => {
  const location = useLocation();
  const router = useRouter();

  if (router.pathname === "/" || router.pathname === "/demo") {
    return location.search;
  }

  if (typeof window !== "undefined") {
    return window.location.search;
  }

  return "";
};

export default useLocationSearch;
