/* eslint-disable react/display-name */
import ExternalLink from "@components/ExternalLink";
import type { WidgetTrack } from "@widget/types";
import React from "react";
import styled, { css, useTheme } from "styled-components";
import Marquee from "./Marquee";

type Props = {
  label: string;
  redirectUrl: string;
  trackType?: WidgetTrack["type"];
};

const SubTitle = React.memo<Props>(({ label, redirectUrl, trackType }) => {
  const theme = useTheme();

  if (trackType !== "episode") {
    return (
      <H2 data-testid="widget-player-content-subtitle">
        <ExternalLink to={redirectUrl}>{label}</ExternalLink>
      </H2>
    );
  }

  return (
    <Marquee
      isCentered
      langDirection={theme.rtl ? "rtl" : "ltr"}
      initialStart
      key={label}
    >
      <H2>
        <ExternalLink to={redirectUrl}>{label}</ExternalLink>
      </H2>
    </Marquee>
  );
});

export default SubTitle;

const ellipsisCss = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.spacing[10]};
  padding-right: ${({ theme }) => theme.spacing[10]};
`;

const H2 = styled.h2`
  position: relative;
  margin: ${({ theme }) => theme.spacing[0]};
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  ${ellipsisCss}
`;
