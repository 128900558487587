import api from "@widget/contexts/api";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@pages/_app";
import type { MutateLikeParams } from "../../types";
import { QUERY_LIKED_PODCASTS_LIST_KEY } from "./useQueryUserLikedPodcastsList";

const mutation = async ({ contentId, isLiking }: MutateLikeParams) => {
  await api.request({
    method: isLiking ? "POST" : "DELETE",
    url: `/podcast/${contentId}/like`,
  });

  return isLiking;
};

type Props = {
  onSuccess: (isLiking: boolean) => void;
};

const useMutatePodcastLike = ({ onSuccess }: Props) => {
  return useMutation(mutation, {
    onSuccess: (isLiking) => {
      queryClient.invalidateQueries([QUERY_LIKED_PODCASTS_LIST_KEY]);
      onSuccess(isLiking);
    },
  });
};

export default useMutatePodcastLike;
