import React, { useContext, useEffect } from "react";
import useQueueList from "@widget/hooks/useQueueList";
import type { WidgetTrack } from "@widget/types";
import { PlayerFormat } from "@widget/player/types";
import styled from "styled-components";
import Player from "@widget/player/Player";
import TrackList from "@widget/tracklist/components/TrackList";
import ColorProvider from "@widget/player/contexts/ColorProvider";

import { useLoadingScreen } from "@widget/components/LoadingScreen";
import { useTracklistVisibility } from "@widget/components/TracklistVisibilityProvider";
import { useErrorContext } from "@widget/components/ErrorProvider";
import { translate } from "@deezer/i18n";
import useLargePlayerCss from "../hooks/useLargePlayerCss";
import { FormatContext } from "../components/FormatProvider";

type Props = {
  isLoading: boolean;
  tracklist?: WidgetTrack[];
  allowSeekJumps?: boolean;
};

const QueuedWidget: React.FC<Props> = ({
  tracklist,
  isLoading,
  allowSeekJumps = false,
}) => {
  const setError = useErrorContext();
  const tracklistVisible = useTracklistVisibility();
  const format = useContext(FormatContext);

  const {
    currentTrack,
    onCentralBtnClick,
    isPlaying,
    onPrevTrack,
    onNextTrack,
    onTrackItemCtrlClick,
  } = useQueueList(tracklist);
  const playerCss = useLargePlayerCss({
    enabled: format === PlayerFormat.Horizontal,
    tracklistVisible,
  });

  useLoadingScreen({ isLoading });

  useEffect(() => {
    if (!isLoading && !tracklist) {
      setError({
        label: translate(
          "errormessage_text_contentnotavailableatthemoment_widget"
        ),
      });
    }
  }, [isLoading, setError, tracklist]);

  if (isLoading) return null;

  const tracksNb = tracklist?.length || 0;

  return (
    <ColorProvider>
      <Container $format={format}>
        <Player
          format={format}
          track={currentTrack}
          isLoading={isLoading}
          isPlaying={isPlaying}
          onCentralBtnClick={onCentralBtnClick}
          onNextTrack={onNextTrack}
          onPrevTrack={onPrevTrack}
          allowQueueCtrl={tracksNb > 1}
          playerCss={playerCss}
          allowSeekJumps={allowSeekJumps}
          tracklistVisible={tracklistVisible}
        />
        {tracklistVisible ? (
          <TrackList
            tracks={tracklist}
            currentTrack={currentTrack}
            isPlaying={isPlaying}
            onTrackItemCtrlClick={onTrackItemCtrlClick}
          />
        ) : null}
      </Container>
    </ColorProvider>
  );
};

export default QueuedWidget;

type ContainerProps = {
  $format?: PlayerFormat;
};

const Container = styled.div<ContainerProps>`
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: ${({ $format }) =>
    $format === PlayerFormat.Horizontal ? "row" : "column"};

  ${({ theme }) =>
    theme.playerRadius &&
    `
      border-radius: ${theme.borderRadius[10]};
    `}

  // Fixes rounded corners on safari
  // Ref: https://forum.webflow.com/t/overflow-hidden-round-corners-not-working-on-safari/67805/5
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
`;
