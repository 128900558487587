import React, { useState, useEffect, useContext } from "react";
import getAppLanguage from "src/i18n/getAppLanguage";
import getSlugFromLocale from "src/i18n/getSlugFromLocale";
import metas from "src/i18n/locales/metas.json";
import { StyleSheetManager } from "styled-components";
import stylisPluginRTL from "stylis-plugin-rtl";

const RTLContext = React.createContext(false);

export const useRTLContext = () => useContext(RTLContext);

export const getIsRTL = () => {
  const lang = getAppLanguage(metas.availableLanguages.map(getSlugFromLocale));

  return lang.startsWith("ar") || lang.startsWith("he");
};

type Props = {
  children: React.ReactNode;
};

const RTLProvider: React.FC<Props> = ({ children }) => {
  const [isRTL, setIsRTL] = useState(false);

  useEffect(() => {
    if (!document) return;
    const nextIsRTL = getIsRTL();
    setIsRTL(nextIsRTL);
    // set dir=rtl attribute to html tag
    if (nextIsRTL) {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    }
  }, []);

  const rtlPlugin = stylisPluginRTL as any; // eslint-disable-line

  if (isRTL) {
    return (
      <RTLContext.Provider value={isRTL}>
        <StyleSheetManager stylisPlugins={[rtlPlugin]}>
          {children}
        </StyleSheetManager>
      </RTLContext.Provider>
    );
  }

  return <>{children}</>;
};

export default RTLProvider;
