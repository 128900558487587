import { useEffect } from "react";
import _get from "lodash/get";
import { useErrorContext } from "@widget/components/ErrorProvider";
import { translate } from "@deezer/i18n";

type UseQueryErrorProps = {
  isError: boolean;
  error: any; // eslint-disable-line
  onNotAvailable?: (message: string) => void;
  onGenericError?: (message: string) => void;
};

const useQueryError = ({
  isError,
  error,
  onNotAvailable,
  onGenericError,
}: UseQueryErrorProps) => {
  const setError = useErrorContext();

  useEffect(() => {
    if (isError) {
      const status = _get(error, "response.status") as number;
      const message = _get(error, "response.data.errors.0.title", "") as string;

      if (message.includes("Not Available")) {
        if (onNotAvailable) {
          onNotAvailable(message);
        } else {
          setError({
            label: translate(
              "errormessage_text_contentnotavailableincountry_widget"
            ),
          });
        }
      } else if (status === 404) {
        setError({
          label: translate(
            "errormessage_text_contentnotavailableatthemoment_widget"
          ),
        });
      } else if (onGenericError) {
        onGenericError(message);
      } else {
        setError({
          label: translate(
            "errormessage_text_erroshasoccurredpleasetryagain_widget"
          ),
          reloadCTA: true,
        });
      }
    } else {
      setError(undefined);
    }
  }, [error, isError, onGenericError, onNotAvailable, setError]);
};

export default useQueryError;
