function getPreferedLanguages(defaultValue: string): string[] {
  let languageList: string[] = [];

  if (typeof window !== "undefined") {
    if (window.navigator.languages) {
      languageList = [...languageList, ...window.navigator.languages];
    }

    if (window.navigator.language) {
      languageList = [...languageList, window.navigator.language];
    }
  }

  languageList.push(defaultValue);

  return languageList;
}

/**
 * Get the requested language if available, otherwise
 * returns the prefered language available
 * @param requestedLanguage {string}
 * @param availableLanguages {string[]}
 * @param defaultValue {string}
 */
export default function getAppLanguage(
  availableLanguages: string[],
  defaultValue = "en-US"
): string {
  let lang = "";
  const langs = getPreferedLanguages(defaultValue);

  for (let index = 0; index < langs.length; index += 1) {
    const matchingLang =
      availableLanguages.find((l) => l === langs[index]) ||
      availableLanguages.find((l) => l.startsWith(langs[index]));

    if (matchingLang) {
      lang = matchingLang;
      break;
    }
  }

  if (!lang) {
    lang = defaultValue;
  }

  return lang;
}
