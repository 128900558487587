import styled from "styled-components";

const FullWidthDiv = styled.div`
  width: 100%;
  height: ${({ theme }) => {
    if (!theme.fullscreen) return "100%";
    if (typeof window === "undefined") return "100vh";
    return `${window.innerHeight}px`;
  }};
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.playerRadius && theme.borderRadius[10]};
  position: relative;
`;

export default FullWidthDiv;
