import React from "react";
import Image from "next/image";
import buildConfig from "build-config.json";

type Props = {
  children: React.ReactNode;
};

const StaticAssetsProvider: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div style={{ display: "none" }}>
        <Image
          src={`${buildConfig.host_assets_ssl}/images/equalizer-playing.gif`}
          alt="Equalizer Gif"
          fill
        />
        <Image
          src={`${buildConfig.host_assets_ssl}/images/assets/empty-cover.jpg`}
          alt="Empty Cover"
          fill
        />
      </div>
      {children}
    </>
  );
};

export default StaticAssetsProvider;
