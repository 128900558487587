import type { SetStateAction} from "react";
import type React from "react";
import { useEffect, useState } from "react";
import { useQueryUserLikedPodcastsList } from "./useQueryUserLikedPodcastsList";

const useQueryUserLikePodcast = (
  podcastId?: string
): [
  boolean | undefined,
  React.Dispatch<SetStateAction<boolean | undefined>>
] => {
  const state = useState<boolean | undefined>(undefined);

  const [, setIsLiking] = state;

  const { data: response } = useQueryUserLikedPodcastsList();

  useEffect(() => {
    if (response && podcastId) {
      const podcasts = response.data?.included;

      if (!podcasts) {
        setIsLiking(false);
      } else {
        const likedPodcastsIds = podcasts.map((t) => t?.id);

        if (likedPodcastsIds.includes(podcastId)) {
          setIsLiking(true);
        } else {
          setIsLiking(false);
        }
      }
    }
  }, [response, setIsLiking, podcastId]);

  return state;
};

export default useQueryUserLikePodcast;
