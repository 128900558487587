import React, { useState } from "react";

type Props = {
  fallback: string;
} & React.HTMLProps<HTMLImageElement>;

const ImageFallback: React.FC<Props> = (props) => {
  const { onError, src, fallback, ...restProps } = props;
  const [showFallback, setShowFallback] = useState(false);
  return (
    <img
      {...restProps}
      src={showFallback ? fallback : src}
      crossOrigin=""
      onError={(e) => {
        if (onError) onError(e);
        setShowFallback(true);
      }}
    />
  );
};

export default ImageFallback;
