import ExternalLink from "@components/ExternalLink";
import React from "react";
import ShareLogoContainer from "./ShareLogoContainer";

type Props = {
  contentUrl: string;
};

const WhatsAppShare: React.FC<Props> = ({ contentUrl }) => {
  return (
    <ExternalLink to={`https://api.whatsapp.com/send?text=${contentUrl}`}>
      <WhatsAppLogo />
    </ExternalLink>
  );
};

export default WhatsAppShare;

function WhatsAppLogo() {
  return (
    <ShareLogoContainer>
      <svg
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="whatsapp-share"
        height="100%"
        width="100%"
      >
        <title id="whatsapp-share">Share to WhatsApp</title>
        <path
          d="M1.518 22.867L1.28515 23.7111L2.13045 23.4827L7.54972 22.018C9.1891 22.9039 11.0295 23.5 12.9621 23.5C19.2726 23.5 24.5 18.19 24.5 11.9241C24.5 5.64932 19.2636 0.5 12.9621 0.5C6.65604 0.5 1.5 5.65378 1.5 11.9241C1.5 13.9174 2.01866 15.8346 2.98023 17.5664L1.518 22.867Z"
          fill="#55CD6C"
          stroke="white"
        />
        <path
          d="M9.76423 6.26643L8.97457 6.22485C8.72517 6.22485 8.47583 6.308 8.30958 6.47425C7.93551 6.80674 7.3121 7.43016 7.14585 8.26144C6.85493 9.50832 7.3121 11.0045 8.39273 12.5008C9.47336 13.997 11.5515 16.4076 15.2089 17.4467C16.3726 17.7791 17.287 17.5713 18.0352 17.1142C18.617 16.7401 18.9911 16.1582 19.1158 15.5348L19.2405 14.9529C19.282 14.7867 19.1989 14.5788 19.0326 14.4957L16.4142 13.2904C16.248 13.2073 16.0401 13.2489 15.9155 13.4151L14.8764 14.7451C14.7933 14.8282 14.6686 14.8698 14.5439 14.8282C13.8374 14.5788 11.4683 13.5813 10.1799 11.0876C10.1383 10.963 10.1383 10.8382 10.2214 10.7551L11.2189 9.63299C11.3021 9.50832 11.3436 9.34207 11.3021 9.21735L10.0968 6.51582C10.0551 6.3911 9.93048 6.26643 9.76423 6.26643Z"
          fill="white"
        />
      </svg>
    </ShareLogoContainer>
  );
}
