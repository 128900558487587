import { useState, useCallback, useRef, useMemo } from "react";
import _throttle from "lodash/throttle";
import useSeekEvents from "./useSeekEvents";
import type {
  ProgressBarRef,
  ProgressBarProps,
  onSeekJumpType,
  onSeekEndType,
  onSeekMoveType,
  onSeekStartType,
} from "../types";
import useProgressBarPercent from "./useProgressBarPercent";

function useSeek(
  pBarRef: ProgressBarRef,
  { currentTime, player, onTimeChange }: ProgressBarProps
) {
  const duration = player?.duration || 0;
  const $percent = duration ? (currentTime * 100) / duration : 0;
  const [$seekPercent, setSeekPercent] = useState(0);
  const isSeeking = useRef(false);
  const mapMoveEventToProgressPercent = useProgressBarPercent(pBarRef);

  const onSeekStart: onSeekStartType = () => {
    if (isSeeking.current) return;
    isSeeking.current = true;
    setSeekPercent($percent);
  };

  const SEEK_MOVE_FREQUENCY_MS = 25;
  const onSeekMove = useMemo(
    () =>
      _throttle((event) => {
        if (!isSeeking.current) return;

        const $nextSeekPercent = mapMoveEventToProgressPercent(event);

        setSeekPercent(($prevSeekPercent) => {
          if (Math.round($nextSeekPercent) === Math.round($prevSeekPercent))
            return $prevSeekPercent;

          return $nextSeekPercent;
        });
      }, SEEK_MOVE_FREQUENCY_MS) as onSeekMoveType,
    [isSeeking, mapMoveEventToProgressPercent, setSeekPercent]
  );

  const onSeekEnd = useCallback<onSeekEndType>(
    (event) => {
      if (!isSeeking.current) return;
      isSeeking.current = false;

      const $currentSeekPercent = mapMoveEventToProgressPercent(event);
      const newCurrentTime = ($currentSeekPercent * duration) / 100;
      onTimeChange(newCurrentTime);
    },
    [duration, isSeeking, mapMoveEventToProgressPercent, onTimeChange]
  );

  const onSeekJump = useCallback<onSeekJumpType>(
    (event) => {
      const JUMP = duration / 10;
      if (event.key === "ArrowLeft") {
        const BACKWARD_TIME_SEC = -JUMP;

        onTimeChange(currentTime + BACKWARD_TIME_SEC);
      }

      if (event.key === "ArrowRight") {
        const FORWARD_TIME_SEC = JUMP;

        onTimeChange(currentTime + FORWARD_TIME_SEC);
      }
    },
    [currentTime, duration, onTimeChange]
  );

  /**
   * If isSeeking, we want to display the "temporary" percent the user is dragging to.
   * If not currently seeking, we want to display the percent corresponding to the
   * real position in the track.
   */
  const $pBarPercent = isSeeking.current ? $seekPercent : $percent;

  const { onMouseDown, onTouchStart, onKeyDown } = useSeekEvents({
    onSeekStart,
    onSeekMove,
    onSeekJump,
    onSeekEnd,
  });

  return {
    onMouseDown,
    onTouchStart,
    onKeyDown,
    $percent: $pBarPercent,
  };
}

export default useSeek;
