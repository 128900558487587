/* eslint-disable @typescript-eslint/no-explicit-any */
import { translate } from "@deezer/i18n";
import type { ModalErrorType } from "@widget/components/ModalError";
import api from "@widget/contexts/api";
import _get from "lodash/get";

// The request was made but no response was received: most probably, user is offline
// `error.request` is an instance of XMLHttpRequest in the browser
export const isOffline = (error: any) => {
  return error.request && error.request.status === 0;
};

export const handleOfflineError = async (error: any) => {
  await waitFor(1000);
  return api.request(error.config);
};

const WHITELISTED_ENDPOINTS = ["token/create-from-session"];

export const mapResponseErrorToModalError = async (
  error: any
): Promise<ModalErrorType | undefined> => {
  let modalError: ModalErrorType | undefined = {
    label: translate("errormessage_text_erroshasoccurredpleasetryagain_widget"),
    reloadCTA: true,
  };

  if (!hasResponse(error)) return modalError;

  const { status } = error.response;

  if (WHITELISTED_ENDPOINTS.includes(error.response.config.url)) {
    return undefined;
  }

  if (status === 401) {
    if (isNotAvailableInCountryError(error)) {
      modalError = {
        label: translate(
          "errormessage_text_contentnotavailableincountry_widget"
        ),
        reloadCTA: false,
      };
    } else if (isTokenInvalid(error)) {
      await waitFor(1000);
      modalError = {
        label: "TOKEN_INVALID",
        reloadCTA: false,
      };
    }
  }

  if (status === 403) {
    modalError = {
      label: translate(
        "errormessage_text_contentnotavailableatthemoment_widget"
      ),
      reloadCTA: false,
    };
  }

  if (status === 404) {
    modalError = undefined;
  }

  return modalError;
};

const hasResponse = (error: any) => Boolean(error.response);

const isNotAvailableInCountryError = (error: any) => {
  const firstError = _get(error.response, "data.errors.0");
  return (
    firstError.detail &&
    firstError.detail.includes("is not authorized in this country")
  );
};

const isTokenInvalid = (error: any) => {
  const firstError = _get(error.response, "data.errors.0");
  return firstError.detail && firstError.detail.includes("Token is not valid");
};

const waitFor = (timeMs: number): Promise<void> =>
  new Promise((res) => {
    setTimeout(res, timeMs);
  });
