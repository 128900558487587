/**
 * Transform a slug to a locale.
 * fr will return fr-FR
 * fr-CA will return fr-CA
 * @param slug string
 * @return string
 */
export default function getLocaleFromSlug(slug: string): string {
  const [lang, region = lang.toUpperCase()] = slug.split("-");

  return `${lang}-${region}`;
}
