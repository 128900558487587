import type { Color } from "@widget/player/contexts/ColorProvider";
import styled from "styled-components";
import React from "react";

type TrackActiveProps = {
  $percent: number;
  color: Color;
};

/**
 * Note: we use a style inline object instead of styled template string, because
 * the values frequently changes ! This is intentional, and not a mistake.
 *
 * When using only styled component, it raises a warning in console:
 *
 * > Consider using the attrs method, together with a style object for frequently changed styles.
 */
const TrackActive: React.FC<TrackActiveProps> = ({ $percent, color }) => {
  return (
    <Container
      style={{
        width: `${$percent}%`,
        background: `${color}`,
      }}
    />
  );
};

export default TrackActive;

const Container = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  /*! @noflip */
  left: 0;
`;
