import { useState, useEffect } from "react";
import type { WidgetTrack } from "@widget/types";
import { useTrackDisabledContext } from "@widget/player/contexts/TrackDisabledContext";
import { useErrorContext } from "@widget/components/ErrorProvider";
import { translate } from "@deezer/i18n";

export type SingletonProps = {
  currentTrack?: WidgetTrack;
  onCentralBtnClick: () => void;
  onNextTrack: () => void;
  isPlaying: boolean;
};

const useSingleton = (track?: WidgetTrack): SingletonProps => {
  const [currentTrack, setCurrentTrack] = useState<WidgetTrack>();
  const [isPlaying, setIsPlaying] = useState(false);

  // Triggers modal error if track or episode couldn't be played
  useSingletonError({ track });

  useEffect(() => {
    if (!currentTrack && track) {
      setCurrentTrack(track);
    }
  }, [currentTrack, track]);

  const onCentralBtnClick = () => {
    setIsPlaying((playing) => !playing);
  };

  const onNextTrack = () => {
    setIsPlaying(false);
  };

  return {
    currentTrack,
    onCentralBtnClick,
    isPlaying,
    onNextTrack,
  };
};

export default useSingleton;

type UseSingletonErrorProps = {
  track?: WidgetTrack;
};

const useSingletonError = ({ track }: UseSingletonErrorProps) => {
  const [disabledTrackMap] = useTrackDisabledContext();
  const setError = useErrorContext();

  useEffect(() => {
    if (!track?.id) return;
    if (disabledTrackMap[track?.id]) {
      setError({
        label: translate(
          "errormessage_text_contentnotavailableatthemoment_widget"
        ),
      });
    }
  }, [disabledTrackMap, setError, track?.id]);
};
