/* eslint-disable react/display-name */
import React from "react";
import type { FlattenSimpleInterpolation} from "styled-components";
import styled, { css } from "styled-components";
import { useSizeMeasurer } from "@widget/components/SizeMeasurerProvider";
import { useLoadingScreen } from "@widget/components/LoadingScreen";
import { PlayerFormat } from "../types";

type Props = {
  $format: PlayerFormat;
  $css?: FlattenSimpleInterpolation;
  $tracklistVisible: boolean;
};

const PlayerCover = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>((props, ref) => {
  const playerSize = useSizeMeasurer();

  useLoadingScreen({ isLoading: !playerSize });

  return <Cover ref={ref} {...props} />;
});

export default PlayerCover;

const Cover = styled.div<Props>`
  position: relative;
  width: 100%;
  color: white;
  background-color: black;
  overflow: hidden;
  height: ${({ theme }) => (theme.fullscreen ? "100vh" : "100%")};
  ${({ $format, $tracklistVisible }) => {
    if (!$tracklistVisible) {
      return css`
        width: ${({ theme }) => (theme.fullscreen ? "100vw" : "100%")};
        height: ${({ theme }) => (theme.fullscreen ? "100vh" : "100%")};
      `;
    }
    if ($format === PlayerFormat.Vertical) {
      return css`
        max-height: 130px;
      `;
    }
    return "";
  }}
  ${({ $css }) => $css}
`;
