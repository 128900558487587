import React from "react";
import { Route, Routes } from "react-router-dom";
import AlbumWidget from "./contexts/album/AlbumWidget";
import PlaylistWidget from "./contexts/playlist/PlaylistWidget";
import TopTracksWidget from "./contexts/artist/TopTracksWidget";
import PodcastWidget from "./contexts/podcast/PodcastWidget";
import TrackWidget from "./contexts/track/TrackWidget";
import TracksWidget from "./contexts/tracks/TracksWidget";
import EpisodeWidget from "./contexts/episode/EpisodeWidget";
import FormatProvider from "./components/FormatProvider";

const Widget: React.FC = () => {
  return (
    <FormatProvider>
      <Routes>
        <Route path="/widget/:theme/album/:albumId" element={<AlbumWidget />} />
        <Route
          path="/widget/:theme/playlist/:playlistId"
          element={<PlaylistWidget />}
        />
        <Route
          path="/widget/:theme/artist/:artistId/top_tracks"
          element={<TopTracksWidget />}
        />
        <Route
          path="/widget/:theme/show/:podcastId"
          element={<PodcastWidget />}
        />
        <Route path="/widget/:theme/track/:trackId" element={<TrackWidget />} />
        <Route
          path="/widget/:theme/tracks/:trackIds"
          element={<TracksWidget />}
        />
        <Route
          path="/widget/:theme/episode/:episodeId"
          element={<EpisodeWidget />}
        />
      </Routes>
    </FormatProvider>
  );
};

export default Widget;
