import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import type { WidgetTrack } from "@widget/types";
import { Virtuoso as List } from "react-virtuoso";
import TrackListItem from "./TrackListItem";

type Props = {
  tracks?: WidgetTrack[];
  currentTrack?: WidgetTrack;
  isPlaying: boolean;
  onTrackItemCtrlClick: (track: WidgetTrack, index: number) => void;
  compact?: boolean;
};

const TrackList: React.FC<Props> = ({
  tracks,
  currentTrack,
  isPlaying,
  onTrackItemCtrlClick,
  compact,
}) => {
  const theme = useTheme();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      setTouched(true);
    }
  }, [isPlaying]);

  // TODO: If no tracks, there is an error !
  if (!tracks) return null;

  return (
    <Container data-testid={`widget-tracklist-${theme.name}`}>
      <List
        className="scrollbar"
        style={{ width: "100%", height: "100%" }}
        totalCount={tracks.length}
        overscan={20}
        itemContent={(index) => {
          const track = tracks[index];
          return (
            <TrackListItem
              key={track.id}
              track={track}
              index={index}
              isPlaying={isPlaying}
              isCurrent={touched ? currentTrack?.id === track.id : false}
              onTrackItemCtrlClick={() => onTrackItemCtrlClick(track, index)}
              compact={compact}
              data-testid={`widget-tracklist-item-${index}`}
            />
          );
        }}
      />
    </Container>
  );
};

export default TrackList;

const Container = styled.ul`
  margin: ${({ theme }) => theme.spacing[0]};
  padding: ${({ theme }) => theme.spacing[0]};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  overflow: hidden;
  flex: 1;
`;
