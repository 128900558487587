import useWindowEvent from "./useWindowEvent";
import type {
  onSeekStartType,
  onSeekEndType,
  onSeekMoveType,
  onSeekJumpType,
} from "../types";

type HookParams = {
  onSeekStart: onSeekStartType;
  onSeekEnd: onSeekEndType;
  onSeekMove: onSeekMoveType;
  onSeekJump: onSeekJumpType;
};

/**
 * This hook handles the DOM events and triggers the corresponding action.
 */
function useSeekEvents({
  onSeekStart,
  onSeekMove,
  onSeekJump,
  onSeekEnd,
}: HookParams) {
  useWindowEvent("mouseup", onSeekEnd);
  useWindowEvent("touchend", onSeekEnd);

  useWindowEvent("mousemove", onSeekMove);
  useWindowEvent("touchmove", onSeekMove);

  return {
    onMouseDown: onSeekStart,
    onTouchStart: onSeekStart,
    onKeyDown: onSeekJump,
  };
}

export default useSeekEvents;
