import { translate } from "@deezer/i18n";
import useCookies from "@hooks/useCookies";
import { useErrorContext } from "@widget/components/ErrorProvider";
import api from "@widget/contexts/api";
import type { ApiResponse } from "@widget/contexts/types";
import { useOptions } from "@widget/queryParams";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@pages/_app";
import type { User } from "./useQueryUserData";
import useQueryUserData from "./useQueryUserData";

type Params = {
  sessionId?: string;
  appId: string;
};

type TokenResponse = ApiResponse<
  {
    token: string;
  },
  unknown
>;

const query = async ({ sessionId, appId }: Params) => {
  let token;

  // If sessionId means: if user is logged on deezer.com
  if (sessionId) {
    try {
      token = await queryLoggedToken({ sessionId, appId });
    } catch {
      // If logged query fails, try set user as unlogged and only get unlogged token
      queryClient.setQueryData(["deezer.getUserData"], (data: any) => {
        // eslint-disable-line
        if (!data) return data;
        const user: User = {
          ...data,
          isLogged: false,
        };
        return user;
      });
      token = await queryUnloggedToken({ appId });
    }
  } else {
    token = await queryUnloggedToken({ appId });
  }

  return token;
};

const queryUnloggedToken = async ({ appId }: Params) => {
  const body = `app_id=${appId}`;
  const { data } = await api.post<TokenResponse>("/token/unlogged", body);
  return data.data?.attributes.token;
};

const queryLoggedToken = async ({
  sessionId,
  appId,
}: {
  sessionId: string;
  appId: string;
}) => {
  const body = new FormData();
  body.append("sid", sessionId);
  body.append("app_id", appId);

  const { data } = await api.post<TokenResponse>(
    "/token/create-from-session",
    body
  );

  return data.data?.attributes.token;
};

const useQueryUserToken = () => {
  const { data: userData, isLoading: isLoadingUserData } = useQueryUserData();
  const [, setToken] = useCookies("token");
  const { app_id } = useOptions();
  const setError = useErrorContext();

  return useQuery(
    ["user", "token"],
    () => {
      return query({
        sessionId: userData?.isLogged ? userData.user?.SESSION_ID : undefined,
        appId: app_id,
      });
    },
    {
      enabled: !isLoadingUserData,
      onSuccess: (token) => setToken(token || null),
      onError: () => {
        setToken(null);
        setError({
          label: translate(
            "errormessage_text_erroshasoccurredpleasetryagain_widget"
          ),
          reloadCTA: true,
        });
      },
    }
  );
};

export default useQueryUserToken;
