import type { ProgressBarRef, ProgressBarProps } from "../types";
import useSeek from "./useSeek";
import useTrackHandle from "./useTrackHandle";
import useBuffer from "./useBuffer";

function useProgressBar(pBarRef: ProgressBarRef, pBarProps: ProgressBarProps) {
  const { trackHandleVisible, onMouseEnter, onMouseLeave } = useTrackHandle();
  const { $percent, onMouseDown, onTouchStart, onKeyDown } = useSeek(
    pBarRef,
    pBarProps
  );
  const buffer = useBuffer(pBarProps.player);

  return {
    $percent,
    onMouseDown,
    onTouchStart,
    onKeyDown,
    trackHandleVisible,
    onMouseEnter,
    onMouseLeave,
    buffer,
  };
}

export default useProgressBar;
