import React from "react";
import type { WidgetTrack } from "@widget/types";
import _omit from "lodash/omit";
import type { FlattenSimpleInterpolation } from "styled-components";
import styled from "styled-components";
import { useLoadingScreen } from "@widget/components/LoadingScreen";
import AdModal from "@widget/player/components/AdModal";
import SizeMeasurerProvider from "@widget/components/SizeMeasurerProvider";
import ImageFallback from "@widget/components/ImageFallback";
import ActionBar from "./components/ActionBar";
import useAudioPlayer from "./hooks/useAudioPlayer";
import ProgressBar from "./progress-bar/components/ProgressBar";
import { PlayerFormat } from "./types";
import useAudioUrl from "./hooks/useAudioUrl";
import DeezerLogo from "./components/DeezerLogo";
import { useColor } from "./contexts/ColorProvider";
import PlayerTitle from "./components/PlayerTitle";
import useStreamlog from "./hooks/useStreamlog";
import SubTitle from "./components/SubTitle";
import PlayerCoverDimmer from "./components/PlayerCoverDimmer";
import PlayerModalProvider from "./components/PlayerModal";
import PlayerContent from "./components/PlayerContent";
import PlayerCover from "./components/PlayerCover";

type Props = {
  format?: PlayerFormat;
  isLoading: boolean;
  isPlaying: boolean;
  allowQueueCtrl: boolean;
  track?: WidgetTrack;
  onCentralBtnClick: () => void;
  onNextTrack: () => void;
  onPrevTrack?: () => void;
  playerCss?: FlattenSimpleInterpolation;
  allowSeekJumps: boolean;
  tracklistVisible: boolean;
};

const Player: React.FC<Props> = ({
  format = PlayerFormat.Vertical,
  isLoading,
  isPlaying,
  track,
  onCentralBtnClick,
  onNextTrack,
  onPrevTrack,
  allowQueueCtrl,
  playerCss,
  allowSeekJumps,
  tracklistVisible,
}) => {
  const trackType = track?.type;
  const {
    playerProps,
    progressBarProps,
    actionBarProps,
    touched,
    isLoading: isAudioLoading,
  } = useAudioPlayer({
    onNextTrack,
    onCentralBtnClick,
    isPlaying,
    track,
  });
  const audioUrl = useAudioUrl({
    track,
    onNotAvailable: onNextTrack,
    isPlaying,
  });
  const { getColor } = useColor();

  useLoadingScreen({ isLoading: isLoading || !track });

  useStreamlog({ track, isPlaying });

  if (isLoading || !track) {
    return null;
  }

  const coverMeta = touched
    ? track.playerMeta.touched
    : track.playerMeta.initial;

  const { title, subtitle, cover } = coverMeta;

  const { onSeekBackward, onSeekForward } = actionBarProps;

  return (
    <SizeMeasurerProvider>
      {({ containerRef }) => (
        <PlayerCover
          $format={format}
          $css={playerCss}
          $tracklistVisible={tracklistVisible}
          ref={containerRef}
          data-testid="widget-player"
        >
          <PlayerModalProvider>
            <Image
              src={cover}
              alt="cover"
              onLoad={getColor}
              crossOrigin=""
              fallback="/images/empty-cover.jpg"
              data-testid="widget-player-cover-image"
            />
            <PlayerCoverDimmer $format={format} />

            <PlayerContent>
              <PlayerTitle
                label={title.label}
                redirectUrl={title.redirectUrl}
              />

              {subtitle ? (
                <SubTitle
                  label={subtitle.label}
                  redirectUrl={subtitle.redirectUrl}
                  trackType={trackType}
                />
              ) : null}

              <ActionsContainer>
                <ActionBar
                  track={track}
                  isPlaying={isPlaying}
                  isLoading={isAudioLoading}
                  onCentralBtnClick={onCentralBtnClick}
                  onPrevClick={allowQueueCtrl ? onPrevTrack : undefined}
                  onNextClick={allowQueueCtrl ? onNextTrack : undefined}
                  onSeekBackward={
                    touched && allowSeekJumps ? onSeekBackward : undefined
                  }
                  onSeekForward={
                    touched && allowSeekJumps ? onSeekForward : undefined
                  }
                  touched={touched}
                  contentUrl={title.redirectUrl}
                />

                <DeezerLogo redirectUrl={title.redirectUrl} />
              </ActionsContainer>

              <ProgressBar
                format={format}
                visible={touched}
                onSpacePress={onCentralBtnClick}
                {...progressBarProps}
              />
            </PlayerContent>

            <AdModal
              redirectTo={title.redirectUrl}
              isPlaying={isPlaying}
              touched={touched}
              trackId={track?.id}
              trackType={trackType}
              isAudioLoading={isAudioLoading}
            />

            <audio
              src={audioUrl as string}
              ref={(r) => playerProps.setPlayer(r)}
              {..._omit(playerProps, "setPlayer")}
            />
          </PlayerModalProvider>
        </PlayerCover>
      )}
    </SizeMeasurerProvider>
  );
};

export default Player;

const Image = styled(ImageFallback)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* TODO: Warning, object-fit support is bad, won't work in any IE browser */
  object-fit: cover;
`;

const ActionsContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[5]};
`;
