import type { SetStateAction} from "react";
import type React from "react";
import { useEffect, useState } from "react";
import { useQueryUserLikedTracksList } from "./useQueryUserLikedTracksList";

const useQueryUserLikeTrack = (
  trackId?: string
): [
  boolean | undefined,
  React.Dispatch<SetStateAction<boolean | undefined>>
] => {
  const state = useState<boolean | undefined>(undefined);

  const [, setIsLiking] = state;

  const { data: response } = useQueryUserLikedTracksList();

  useEffect(() => {
    if (response && trackId) {
      const tracks = response.data?.included;

      if (!tracks) {
        setIsLiking(false);
      } else {
        const likedTracksIds = tracks.map((t) => t?.id);

        if (likedTracksIds.includes(trackId)) {
          setIsLiking(true);
        } else {
          setIsLiking(false);
        }
      }
    }
  }, [response, setIsLiking, trackId]);

  return state;
};

export default useQueryUserLikeTrack;
