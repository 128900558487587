import styled, { keyframes, css } from "styled-components";

type Props = {
  $wide?: boolean;
  $animate?: boolean;
};

const smoothMount = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
`;

const ActionButton = styled.button<Props>`
  font-size: ${({ theme }) => theme.fontSize.xl};
  background-color: transparent;
  border: ${({ theme }) => theme.borderWidth[0]};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  min-height: 25px;
  padding-left: ${({ $wide, theme }) =>
    $wide ? theme.spacing[30] : theme.spacing[10]};
  padding-right: ${({ $wide, theme }) =>
    $wide ? theme.spacing[30] : theme.spacing[10]};

  &:hover {
    cursor: pointer;
  }

  ${({ $animate = true }) =>
    $animate &&
    css`
      animation: 0.3s ease-out 0s 1 ${smoothMount};
    `}
`;

export default ActionButton;
