import React, { useContext } from "react";
import type { DimensionObject } from "@hooks/useDimensions";

const useWidgetDimensions = () => useContext(WidgetDimensionsContext);

const WidgetDimensionsContext = React.createContext<
  DimensionObject | undefined
>(undefined);

export { useWidgetDimensions, WidgetDimensionsContext };
