import React from "react";
import type { WidgetTrack } from "@widget/types";
import { StreamLogContextType } from "@widget/types";
import { useParams } from "react-router-dom";
import SingletonWidget from "@widget/formats/SingletonWidget";
import { dzrLink } from "@components/ExternalLink";
import useQueryError from "@widget/hooks/useQueryError";
import { useOptions } from "@widget/queryParams";
import getUTMParams from "@widget/utils/getUTMParams";
import { useEpisode } from "./useEpisode";
import type { EpisodeApiResponse } from "./types";
import useCollectionApi from "../collection/useCollectionApi";
import type { PodcastApiResponse } from "../podcast/types";

type RouteParams = {
  episodeId: string;
};

const EpisodeWidget: React.FC = () => {
  const { episodeId = "" } = useParams<RouteParams>();
  const {
    isLoading: isEpisodeLoading,
    data,
    isError: isEpisodeError,
    error: episodeError,
  } = useEpisode(episodeId);
  useQueryError({ isError: isEpisodeError, error: episodeError });

  const podcastId = getPodcastId(data);
  const {
    isLoading: isPodcastLoading,
    data: podcast,
    isError: isPodcastError,
    error: podcastError,
  } = useCollectionApi<PodcastApiResponse>({
    name: "podcast",
    id: podcastId,
  });
  useQueryError({ isError: isPodcastError, error: podcastError });
  const { app_id } = useOptions();
  const utm = getUTMParams({
    appId: app_id,
    contextId: episodeId,
    contextType: "episode",
  });

  const episode = getEpisode(data, podcast, utm);

  const isLoading = isEpisodeLoading || isPodcastLoading;

  return (
    <SingletonWidget track={episode} isLoading={isLoading} allowSeekJumps />
  );
};

export default EpisodeWidget;

const getPodcastId = (episodeResponse?: EpisodeApiResponse) => {
  if (!episodeResponse || !episodeResponse.data?.attributes) return undefined;

  return episodeResponse.data.attributes.podcastId;
};

export const getEpisode = (
  episodeResponse?: EpisodeApiResponse,
  podcastResponse?: PodcastApiResponse,
  utm?: string
): WidgetTrack | undefined => {
  if (!episodeResponse || !episodeResponse.data?.attributes) return undefined;
  if (!podcastResponse || !podcastResponse.data?.attributes) return undefined;

  const podcast = podcastResponse.data.attributes;
  const episode = episodeResponse.data.attributes;

  return {
    type: "episode",
    id: episodeResponse.data.id,
    cover: episode.image.medium,
    readable: true,
    title: episode.title,
    audioUrl: episode.url,
    contextType: StreamLogContextType.Episode,
    contextId: episodeResponse.data.id,
    playerMeta: {
      initial: {
        title: {
          label: podcast.title,
          redirectUrl: dzrLink("show", podcastResponse.data.id, utm),
        },
        subtitle: {
          label: episode.title,
          redirectUrl: dzrLink("episode", episodeResponse.data.id, utm),
        },
        cover: episode.image.large,
      },
      touched: {
        title: {
          label: podcast.title,
          redirectUrl: dzrLink("show", podcastResponse.data.id, utm),
        },
        subtitle: {
          label: episode.title,
          redirectUrl: dzrLink("episode", episodeResponse.data.id, utm),
        },
        cover: episode.image.large,
      },
    },
    sharingMenuMeta: {
      redirectUrl: dzrLink("episode", episodeResponse.data.id, utm),
    },
  };
};
