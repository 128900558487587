import React from "react";
import styled from "styled-components";
import { usePlayerModal } from "./PlayerModal";

type Props = {
  children: React.ReactNode;
};

const PlayerContent: React.FC<Props> = (props) => {
  const [{ isVisible }] = usePlayerModal();

  if (isVisible) return null;

  return <Container {...props} />;
};

export default PlayerContent;

const Container = styled.div`
  position: absolute;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: opacity 0.3s ease-in-out;
`;
