/* eslint-disable react/display-name */
import ExternalLink from "@components/ExternalLink";
import React from "react";
import styled, { useTheme } from "styled-components";
import Marquee from "./Marquee";

type Props = {
  label: string;
  redirectUrl: string;
};

const PlayerTitle = React.memo<Props>(({ label, redirectUrl }) => {
  const theme = useTheme();
  return (
    <Marquee
      isCentered
      langDirection={theme.rtl ? "rtl" : "ltr"}
      initialStart
      key={label}
    >
      <Title data-testid="widget-player-content-title">
        <ExternalLink to={redirectUrl}>{label}</ExternalLink>
      </Title>
    </Marquee>
  );
});

export default PlayerTitle;

const Title = styled.h1`
  position: relative;
  margin: ${({ theme }) => theme.spacing[0]};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: bold;
  line-height: 30px;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  margin-top: ${({ theme }) => theme.spacing[10]};
  color: ${({ theme }) => theme.colors.whitesmoke};
`;
