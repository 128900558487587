import { useState, useEffect } from "react";
import type { WidgetTrack } from "@widget/types";

type CurrentTrack = {
  index: number;
  data?: WidgetTrack;
};

export type QueueProps = {
  currentTrack?: WidgetTrack;
  onPrevTrack: () => void;
  onNextTrack: () => void;
  onCentralBtnClick: () => void;
  isPlaying: boolean;
  onTrackItemCtrlClick: (track: WidgetTrack, index: number) => void;
};

const useQueueList = (tracklist?: WidgetTrack[]): QueueProps => {
  const [currentTrack, setCurrentTrack] = useState<CurrentTrack>({
    index: 0,
    data: undefined,
  });
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!currentTrack.data && tracklist && tracklist.length > 0) {
      setCurrentTrack({ index: 0, data: tracklist[0] });
    }
  }, [currentTrack, tracklist]);

  const onPrevTrack = () => {
    if (!tracklist) return;

    setCurrentTrack((track) => {
      const prevIndex = tracklist[track.index - 1]
        ? track.index - 1
        : tracklist.length - 1;
      return {
        index: prevIndex,
        data: tracklist[prevIndex],
      };
    });
  };

  const onNextTrack = () => {
    if (!tracklist) return;

    setCurrentTrack((track) => {
      const nextIndex = tracklist[track.index + 1] ? track.index + 1 : 0;
      if (nextIndex === 0) {
        setIsPlaying(false);
      }
      return {
        index: nextIndex,
        data: tracklist[nextIndex],
      };
    });
  };

  const onCentralBtnClick = () => {
    setIsPlaying((playing) => !playing);
  };

  const onTrackItemCtrlClick = (track: WidgetTrack, index: number) => {
    setCurrentTrack((currentTrackState) => {
      setIsPlaying((currentIsPlaying) => {
        if (track.id === currentTrackState?.data?.id) {
          return !currentIsPlaying;
        }
        return true;
      });

      return { index, data: track };
    });
  };

  return {
    currentTrack: currentTrack.data,
    onPrevTrack,
    onNextTrack,
    onCentralBtnClick,
    isPlaying,
    onTrackItemCtrlClick,
  };
};

export default useQueueList;
