import ExternalLink from "@components/ExternalLink";
import React from "react";
import qs from "qs";
import ShareLogoContainer from "./ShareLogoContainer";

type Props = {
  contentUrl: string;
};

const TwitterShare: React.FC<Props> = ({ contentUrl }) => {
  const params = {
    url: contentUrl,
    related: "deezer",
  };

  return (
    <ExternalLink
      to={`https://twitter.com/intent/tweet?${qs.stringify(params)}`}
    >
      <TwitterLogo />
    </ExternalLink>
  );
};

export default TwitterShare;

function TwitterLogo() {
  return (
    <ShareLogoContainer>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="twitter-share"
        height="100%"
        width="100%"
      >
        <title id="twitter-share">Share to Twitter</title>
        <path
          d="M7.91884 21.289C16.2213 21.289 20.7612 14.4109 20.7612 8.44659C20.7612 8.25123 20.7573 8.05675 20.7485 7.86315C21.6298 7.22602 22.3959 6.43093 23 5.52584C22.1913 5.88533 21.3209 6.12733 20.4079 6.23645C21.3399 5.67765 22.0553 4.79367 22.3928 3.73986C21.5207 4.25687 20.5549 4.63263 19.5266 4.83548C18.7029 3.95811 17.5303 3.40942 16.2319 3.40942C13.7392 3.40942 11.7178 5.4308 11.7178 7.92255C11.7178 8.27675 11.7574 8.62127 11.8349 8.95172C8.08341 8.76295 4.75698 6.96686 2.531 4.23575C2.14336 4.9028 1.91984 5.67764 1.91984 6.50441C1.91984 8.07039 2.71668 9.45288 3.92845 10.2616C3.18793 10.2387 2.49228 10.0354 1.88419 9.69708C1.88353 9.71602 1.88353 9.73449 1.88353 9.75473C1.88353 11.9407 3.43938 13.7658 5.50477 14.1794C5.12548 14.2828 4.7264 14.3383 4.31455 14.3383C4.02415 14.3383 3.74123 14.3097 3.46622 14.2569C4.04087 16.0503 5.70717 17.3554 7.68279 17.3919C6.13793 18.6028 4.19179 19.3239 2.07669 19.3239C1.71281 19.3239 1.35332 19.3032 1 19.2615C2.99762 20.5419 5.36968 21.289 7.91907 21.289"
          fill="#1DA1F2"
        />
      </svg>
    </ShareLogoContainer>
  );
}
