import React from "react";
import type { WidgetTrack } from "@widget/types";
import { StreamLogContextType } from "@widget/types";
import { useParams } from "react-router-dom";
import QueuedWidget from "@widget/formats/QueuedWidget";
import { dzrLink } from "@components/ExternalLink";
import useQueryError from "@widget/hooks/useQueryError";
import { useOptions } from "@widget/queryParams";
import getUTMParams from "@widget/utils/getUTMParams";
import type { AlbumApiResponse } from "./types";
import useQueryAlbum from "./useQueryAlbum";

type RouteParams = {
  albumId: string;
};

const AlbumWidget: React.FC = () => {
  const { albumId = "" } = useParams<RouteParams>();
  const { isLoading, data: album, isError, error } = useQueryAlbum({ albumId });
  useQueryError({ isError, error });
  const { app_id } = useOptions();

  const utm = getUTMParams({
    appId: app_id,
    contextId: albumId,
    contextType: "album",
  });

  const tracklist = getTracklist(album, utm);
  return <QueuedWidget tracklist={tracklist} isLoading={isLoading} />;
};

export default AlbumWidget;

export const getTracklist = (
  album?: AlbumApiResponse,
  utm?: string
): WidgetTrack[] | undefined => {
  if (!album || !album?.data?.included) return undefined;

  const albumMeta = album.data.attributes;
  const albumId = album.data?.id as string;

  return album.data.included.map((track) => {
    const trackMeta = track.attributes;

    return {
      type: "track",
      id: track.id,
      cover: trackMeta.image.medium,
      readable: true,
      title: trackMeta.title,
      audioUrl: "",
      contextType: StreamLogContextType.Album,
      contextId: albumId,
      playerMeta: {
        initial: {
          title: {
            label: albumMeta.name,
            redirectUrl: dzrLink("album", albumId, utm),
          },
          subtitle: {
            label: trackMeta.artistName,
            redirectUrl: dzrLink("artist", trackMeta.artistId, utm),
          },
          cover: albumMeta.image.large,
        },
        touched: {
          title: {
            label: trackMeta.title,
            redirectUrl: dzrLink("album", albumId, utm),
          },
          subtitle: {
            label: trackMeta.artistName,
            redirectUrl: dzrLink("artist", trackMeta.artistId, utm),
          },
          cover: trackMeta.image.large,
        },
      },
      tracklistItemMeta: {
        title: {
          label: trackMeta.title,
        },
        subtitle: {
          label: trackMeta.artistName,
          redirectUrl: dzrLink("artist", trackMeta.artistId, utm),
        },
      },
      sharingMenuMeta: {
        redirectUrl: dzrLink("track", track.id, utm),
      },
    };
  });
};
