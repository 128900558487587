import React, { useContext, useEffect, useState } from "react";
import type {
  DefaultTheme} from "styled-components";
import {
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { darkTheme, lightTheme } from "src/theme";
import useUrl from "@hooks/useUrl";
import WidgetPreviewContext from "@portal/components/WidgetPreviewContext";
import useDarkMatchMedia from "@hooks/useDarkMatchMedia";
import { useOptions } from "@widget/queryParams";
import { useRTLContext } from "./RTLProvider";

/**
 * We needed to define the theme in the url path directly (instead of a query param)
 * because we need nextjs to generate the static pre-renders for both light and
 * dark theme !
 *
 * It allows us to avoid "flashing" (from light to dark) by rendering
 * the "dark" theme right away when the browser asks it, instead of having to wait
 * for the JS to load in the page and change the current theme.
 */

type Props = {
  children: React.ReactNode;
};

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState<DefaultTheme>();
  const url = useUrl();
  const previewContext = useContext(WidgetPreviewContext);
  const { matches: darkMatchMedia, cleanup } = useDarkMatchMedia();
  const isRTL = useRTLContext();
  const { radius } = useOptions();

  useEffect(() => {
    if (url.includes("widget/auto") && hasWindow() && darkMatchMedia) {
      setTheme(darkMatchMedia ? darkTheme : lightTheme);
    } else {
      cleanup();
      if (url.includes("widget/dark")) {
        setTheme(darkTheme);
      } else {
        setTheme(lightTheme);
      }
    }
  }, [cleanup, darkMatchMedia, url]);

  if (!theme) {
    return null;
  }

  // If in preview context (in the creation portal), do not render in fullscreen
  theme.fullscreen = !previewContext;

  // If RTL
  theme.rtl = isRTL;

  theme.playerRadius = radius;

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;

const hasWindow = () => typeof window !== "undefined";
