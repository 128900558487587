import React, { useContext, useState, useMemo } from "react";

type ContextType = [
  Record<string, boolean>,
  (disabledTrack: Record<string, boolean>) => void
];

const TrackDisabledContext = React.createContext<ContextType>([
  {},
  () => {
    throw new Error("Track available context not initialized");
  },
]);

export const useTrackDisabledContext = () => useContext(TrackDisabledContext);

type Props = {
  children: React.ReactNode;
};

const TrackUnavailableProvider: React.FC<Props> = ({ children }) => {
  const [disabledMap, setDisabledMap] = useState({});

  function setDisabledTrack(trackUnavailable: Record<string, boolean>) {
    setDisabledMap((m) => ({ ...m, ...trackUnavailable }));
  }

  const contextValue = useMemo(
    () => [disabledMap, setDisabledTrack] as ContextType,
    [disabledMap]
  );

  return (
    <TrackDisabledContext.Provider value={contextValue}>
      {children}
    </TrackDisabledContext.Provider>
  );
};

export default TrackUnavailableProvider;
