import ExternalLink from "@components/ExternalLink";
import React from "react";
import qs from "qs";
import isMobileDevice from "@widget/utils/isMobileDevice";
import ShareLogoContainer from "./ShareLogoContainer";
import { FACEBOOK_APP_ID } from "./constants";

type Props = {
  contentUrl: string;
};

const MessengerShare: React.FC<Props> = ({ contentUrl }) => {
  const params = {
    app_id: FACEBOOK_APP_ID,
    link: contentUrl,
    redirect_uri: contentUrl,
    display: "page",
  };

  if (isMobileDevice()) return null;

  return (
    <ExternalLink
      to={`https://www.facebook.com/dialog/send?${qs.stringify(params)}`}
    >
      <MessengerLogo />
    </ExternalLink>
  );
};

export default MessengerShare;

function MessengerLogo() {
  return (
    <ShareLogoContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 800 800"
        aria-labelledby="messenger-share"
        height="100%"
        width="100%"
      >
        <title id="messenger-share">Share to messenger</title>
        <radialGradient
          id="Gradient"
          cx="101.925"
          cy="809.0079"
          r="1.0896"
          gradientTransform="matrix(800 0 0 -799.9985 -81386 648000.75)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" style={{ stopColor: "#0099FF" }} />
          <stop offset="0.6098" style={{ stopColor: "#A033FF" }} />
          <stop offset="0.9348" style={{ stopColor: "#FF5280" }} />
          <stop offset="1" style={{ stopColor: "#FF7061" }} />
        </radialGradient>
        <path
          fill="url(#Gradient)"
          d="M400,0C174.7,0,0,165.1,0,388c0,116.6,47.8,217.4,125.6,287c6.5,5.8,10.5,14,10.7,22.8l2.2,71.2  c0.7,22.7,24.1,37.5,44.9,28.3l79.4-35c6.7-3,14.3-3.5,21.4-1.6c36.5,10,75.3,15.4,115.8,15.4c225.3,0,400-165.1,400-388  S625.3,0,400,0z"
        />
        <path
          fill="#FFFFFF"
          d="M159.8,501.5l117.5-186.4c18.7-29.7,58.7-37,86.8-16l93.5,70.1c8.6,6.4,20.4,6.4,28.9-0.1    l126.2-95.8c16.8-12.8,38.8,7.4,27.6,25.3L522.7,484.9c-18.7,29.7-58.7,37-86.8,16l-93.5-70.1c-8.6-6.4-20.4-6.4-28.9,0.1    l-126.2,95.8C170.5,539.5,148.5,519.4,159.8,501.5z"
        />
      </svg>
    </ShareLogoContainer>
  );
}
