import type { SyntheticEvent} from "react";
import React, { useState, useMemo } from "react";
import {
  pickColor,
  getColorKeyFromTone,
  getToneFromHSLColor,
  colors,
} from "@deezer/colors";

export type Color = string;

type ColorContext = {
  color: Color;
  getColor: (event: SyntheticEvent) => void;
};

const defaultColor = colors.blue;
export const ColorContext = React.createContext<ColorContext>({
  color: defaultColor,
  getColor: () => null,
});

type Props = {
  children: React.ReactNode;
};

const ColorProvider: React.FC<Props> = (props) => {
  const [color, setColor] = useState(defaultColor);

  const getColorFromCover = (event: SyntheticEvent): void => {
    const image = event.target as HTMLImageElement;
    let colorKey = getColorKeyFromTone("blue");

    try {
      const { color: pickedColor } = pickColor(image);
      colorKey = getColorKeyFromTone(getToneFromHSLColor(pickedColor));

      // override acid (yellow) color for contrast purposes
      if (colorKey === "acid") {
        colorKey = "orange";
      }
    } catch (error) {
      colorKey = getColorKeyFromTone("blue");
    }

    setColor(colors[colorKey]);
  };

  const value = useMemo(
    () => ({ color, getColor: getColorFromCover }),
    [color]
  );

  return <ColorContext.Provider value={value} {...props} />;
};

export const useColor = () => React.useContext(ColorContext);

export default ColorProvider;
