import { useCallback, useMemo, useState } from "react";

const useTrackItemHover = (): [boolean, Record<string, unknown>] => {
  const [hovering, setHovering] = useState(false);

  const onMouseEnter = useCallback(() => setHovering(true), []);
  const onFocus = useCallback(() => setHovering(true), []);
  const onMouseLeave = useCallback(() => setHovering(false), []);
  const onBlur = useCallback(() => setHovering(false), []);

  return useMemo(
    () => [hovering, { onMouseEnter, onFocus, onMouseLeave, onBlur }],
    [hovering, onBlur, onFocus, onMouseEnter, onMouseLeave]
  );
};

export default useTrackItemHover;
