import { translate } from "@deezer/i18n";
import usePrevious from "@hooks/usePrevious";
import type { WidgetTrack } from "@widget/types";
import type React from "react";
import { useEffect } from "react";
import useAdModalVisibility from "../hooks/useAdModalVisibility";
import { usePlayerModal } from "./PlayerModal";

type Props = {
  redirectTo: string;
  touched: boolean;
  isPlaying: boolean;
  trackId?: WidgetTrack["id"];
  trackType?: WidgetTrack["type"];
  isAudioLoading: boolean;
};

const AdModal: React.FC<Props> = (props) => {
  const { trackType, redirectTo } = props;
  const [playerModal, setPlayerModal] = usePlayerModal();
  const { modalVisible, closeModal } = useAdModalVisibility(props);
  const prevModalVisible = usePrevious(playerModal.isVisible);

  const modalJustClosed =
    prevModalVisible === true && playerModal.isVisible === false;

  useEffect(() => {
    if (!modalJustClosed && modalVisible && !playerModal.isVisible) {
      const text =
        trackType === "episode"
          ? translate(
              "infomessage_text_discovermorepodcastsforfreeondeezer_widget"
            )
          : translate("infomessage_text_listentofullcontentondeezer_widget");
      const buttonText = translate("generic_action_gotodeezer_widget");

      setPlayerModal({ isVisible: true, buttonText, text, redirectTo });
    }
  }, [
    modalJustClosed,
    modalVisible,
    playerModal.isVisible,
    redirectTo,
    setPlayerModal,
    trackType,
  ]);

  useEffect(() => {
    // playerModal.isVisible could be added to show modal no matter if
    // it has already been prompted by something else (ex: like modal)
    // or not
    if (modalJustClosed) {
      closeModal();
    }
  }, [closeModal, modalJustClosed]);

  return null;
};

export default AdModal;
