import type ActionButton from "@widget/player/components/ActionButton";
import { usePlayerModal } from "@widget/player/components/PlayerModal";
import React, { useCallback } from "react";
import { translate } from "@deezer/i18n";
import type { WidgetTrack } from "@widget/types";
import ActionLikeTrackButton from "./ActionLikeTrackButton";
import EmptyActionIcon from "../../components/EmptyActionIcon";
import ActionLikePodcastButton from "./ActionLikePodcastButton";

type Props = {
  track?: WidgetTrack;
  playerSize: "XS" | "SM" | "MD" | "LG" | "XL";
  touched: boolean;
  redirectUnloggedTo: string;
} & React.ComponentProps<typeof ActionButton>;

const ActionLikeButton: React.FC<Props> = ({
  track,
  touched,
  playerSize,
  redirectUnloggedTo,
  ...buttonProps
}) => {
  const [, setPlayerModal] = usePlayerModal();

  const showRedirectionModal = useCallback(() => {
    setPlayerModal({
      isVisible: true,
      buttonText: translate("generic_action_gotodeezer_widget"),
      redirectTo: redirectUnloggedTo,
      text: translate("infomessage_text_logintoperformthisaction_widget"),
    });
  }, [setPlayerModal, redirectUnloggedTo]);

  if (playerSize === "XS" || !touched) {
    return <EmptyActionIcon />;
  }

  if (track?.type === "episode") {
    return (
      <ActionLikePodcastButton
        data-testid="widget-user-actions-like-button"
        track={track}
        onUnloggedUserToggle={showRedirectionModal}
        {...buttonProps}
      />
    );
  }

  return (
    <ActionLikeTrackButton
      data-testid="widget-user-actions-like-button"
      track={track}
      onUnloggedUserToggle={showRedirectionModal}
      {...buttonProps}
    />
  );
};

export default ActionLikeButton;
