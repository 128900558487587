import { useQuery } from "@tanstack/react-query";
import api from "../api";
import type { EpisodeApiResponse } from "./types";

async function queryTrack(episodeId: string): Promise<EpisodeApiResponse> {
  const { data } = await api.get<EpisodeApiResponse>(`/episode/${episodeId}`);

  return data;
}

type EpisodeQueryKey = [string, string | undefined];
export function useEpisode(episodeId: string) {
  return useQuery<EpisodeApiResponse, EpisodeQueryKey>(
    ["episode", episodeId],
    () => queryTrack(episodeId),
    {
      enabled: Boolean(episodeId),
    }
  );
}
