import { PlayerFormat } from "@widget/player/types";
import React, { useEffect, useState } from "react";
import { useWidgetDimensions } from "@widget/hooks/useWidgetDimensions";
import { useOptions } from "@widget/queryParams";

export const FormatContext = React.createContext<PlayerFormat>(
  PlayerFormat.Auto
);

type Props = {
  children: React.ReactNode;
};

const FormatProvider: React.FC<Props> = (props) => {
  const [format, setFixedFormat] = useState(PlayerFormat.Vertical);
  const dimensions = useWidgetDimensions();

  const { format: formatQP } = useOptions();

  useEffect(() => {
    if (
      formatQP === PlayerFormat.Vertical ||
      formatQP === PlayerFormat.Horizontal
    ) {
      setFixedFormat(formatQP);
    }
  }, [formatQP]);

  useEffect(() => {
    if (
      formatQP !== PlayerFormat.Vertical &&
      formatQP !== PlayerFormat.Horizontal
    ) {
      if (!dimensions) return;
      const { width, height } = dimensions;

      if (width >= 1.9 * height) {
        setFixedFormat(PlayerFormat.Horizontal);
      } else {
        setFixedFormat(PlayerFormat.Vertical);
      }
    }
  }, [dimensions, formatQP]);

  return <FormatContext.Provider value={format} {...props} />;
};

export default FormatProvider;
