import { useQuery } from "@tanstack/react-query";
import api from "../api";
import type { TrackApiResponse } from "./types";

async function queryTrack(trackId: string): Promise<TrackApiResponse> {
  const { data } = await api.get<TrackApiResponse>(`/track/${trackId}`);

  return data;
}

type TrackQueryKey = [string, string | undefined];
export function useTrack(trackId: string) {
  return useQuery<TrackApiResponse, TrackQueryKey>(
    ["track", trackId],
    () => queryTrack(trackId),
    {
      enabled: Boolean(trackId),
    }
  );
}
