import useQueryUserToken from "@widget/authorization/hooks/useQueryUserToken";
import api from "@widget/contexts/api";
import { useEffect, useRef, useState } from "react";

const useAuthToken = () => {
  const { data: token, refetch, isSuccess } = useQueryUserToken();
  const [isAuthorized, setAuthorized] = useState(false);
  const interceptorRequestId = useRef<number>();

  const queryToken = async () => {
    const result = await refetch();
    return result.data;
  };

  // Injects the token took from cookies into every request
  useEffect(() => {
    interceptorRequestId.current = api.interceptors.request.use((config) => {
      const requestConfig = { ...config };
      requestConfig.headers = config.headers || {};
      requestConfig.headers.authorization = token
        ? `Bearer ${token}`
        : undefined;
      return requestConfig;
    });

    return () => {
      if (typeof interceptorRequestId.current !== "undefined") {
        api.interceptors.request.eject(interceptorRequestId.current);
      }
    };
  }, [token]);

  useEffect(() => {
    setAuthorized(isSuccess);
  }, [isSuccess]);

  return { isAuthorized, queryToken };
};

export default useAuthToken;
