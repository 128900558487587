/* eslint-disable no-underscore-dangle, react/sort-comp, react/destructuring-assignment, class-methods-use-this */
import React, { Component } from "react";
import cx from "clsx";
import debounce from "lodash/debounce";
import styled from "styled-components";
import { withWidgetPreviewContext } from "@portal/components/WidgetPreviewContext";

// Constants
const MARQUEE_STEP = 0.8; // Number of pixels per frame
const MARQUEE_SPACER = 130; // Number of minimal visible text pixels

type Props = {
  children: React.ReactNode;
  isCentered: boolean;
  onScrollStart?: () => void;
  onScrollEnd?: () => void;
  langDirection: string;
  initialStart?: boolean;
  previewContext?: Record<string, unknown>;
};

type State = {
  isTruncated: boolean;
};

/**
 * This component has been taken and adapted from Slash, this is why
 * the code looks different than elsewhere
 */
class Marquee extends Component<Props, State> {
  _containerRef: HTMLDivElement | null;

  _containerWidth: number;

  _wrapperRef: HTMLDivElement | null;

  _childrenRef: HTMLDivElement | null;

  _frameId: number | null;

  _initialStartTimeout: NodeJS.Timeout | null;

  _scrollOffset: number;

  _scrollWidth: number;

  _isLastLoop: boolean;

  _onResize: VoidFunction;

  constructor(props: Props) {
    super(props);

    this._containerRef = null;
    this._containerWidth = 0;
    this._wrapperRef = null;
    this._childrenRef = null;
    this._scrollOffset = 0;
    this._scrollWidth = 0;
    this._frameId = null;
    this._initialStartTimeout = null;
    this._isLastLoop = false;

    this._onResize = debounce(this._measureText.bind(this), 100);

    this.state = {
      isTruncated: false,
    };
  }

  componentDidMount() {
    const { initialStart } = this.props;
    const isTruncated = this._measureText();
    if (initialStart && isTruncated) {
      this._initialStartTimeout = setTimeout(() => {
        this._start();
        this._stopFutureLoop();
      }, 2000);
    }

    window.addEventListener("resize", this._onResize, false);
  }

  componentDidUpdate(prevProps: Props) {
    const { previewContext: prevPreviewContext } = prevProps;
    const { previewContext } = this.props;
    if (previewContext !== prevPreviewContext) {
      this._onResize();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ isTruncated: false }, () => {
      this._measureText();
    });
  }

  componentWillUnmount() {
    this._clear();

    window.removeEventListener("resize", this._onResize);
  }

  render() {
    const { langDirection, isCentered } = this.props;
    const { isTruncated } = this.state;

    const wrapperProps: Record<string, unknown> = {
      className: cx("marquee-wrapper", {
        "is-centered": isCentered && !isTruncated,
      }),
      ref: (ref: HTMLDivElement) => {
        this._wrapperRef = ref;
      },
    };

    if (isTruncated) {
      wrapperProps.onMouseEnter = () => this._start();
      wrapperProps.onMouseLeave = () => this._stopFutureLoop();
      wrapperProps.style = this._getWrapperStyle(0);
    }

    return (
      <MarqueeContainer
        className="marquee"
        dir={langDirection}
        ref={(ref) => {
          this._containerRef = ref;
        }}
      >
        <MarqueeWrapper {...wrapperProps}>
          {this._renderContent()}
        </MarqueeWrapper>
      </MarqueeContainer>
    );
  }

  _renderContent() {
    const { children } = this.props;

    return (
      <MarqueeContent
        className="marquee-content"
        ref={(ref) => {
          this._childrenRef = ref;
        }}
      >
        {children}
      </MarqueeContent>
    );
  }

  _measureText() {
    if (!this._childrenRef || !this._containerRef) {
      return undefined;
    }

    const isTruncated =
      this._childrenRef.offsetWidth > this._containerRef.offsetWidth;
    if (this.state.isTruncated === isTruncated) {
      return undefined;
    }

    this.setState({ isTruncated });

    return isTruncated;
  }

  _start() {
    if (!this._childrenRef || !this._containerRef) return;
    const { onScrollStart } = this.props;

    if (this._isLastLoop) {
      this._isLastLoop = false;
    }

    if (this._isScrolling()) {
      return;
    }

    this._scrollWidth = this._childrenRef.offsetWidth;
    this._containerWidth = this._containerRef.offsetWidth;

    if (typeof onScrollStart === "function") {
      onScrollStart();
    }

    this._frameId = window.requestAnimationFrame(this._move.bind(this));
  }

  _stopFutureLoop() {
    if (!this._isScrolling()) {
      return;
    }

    this._isLastLoop = true;
  }

  _move() {
    this._frameId = window.requestAnimationFrame(this._move.bind(this));
    this._scrollOffset = Math.ceil(this._scrollOffset + MARQUEE_STEP);

    if (this._scrollOffset > this._scrollWidth - MARQUEE_SPACER) {
      this._scrollOffset = 0;
    }

    this._moveTo(this._scrollOffset);

    if (this._scrollOffset === 0 && this._isLastLoop) {
      const { onScrollEnd } = this.props;

      if (typeof onScrollEnd === "function") {
        onScrollEnd();
      }

      this._clear();
    }
  }

  _moveTo(offset: number) {
    if (!this._wrapperRef) {
      return;
    }

    this._setStyle(
      this._wrapperRef,
      this._getWrapperStyle(this._getTranslateOffset(offset))
    );
  }

  _clear() {
    if (this._isScrolling() && this._frameId) {
      window.cancelAnimationFrame(this._frameId);
    }

    this._moveTo(0);
    this._frameId = null;
    this._isLastLoop = false;
    this._scrollOffset = 0;

    if (this._initialStartTimeout) {
      clearTimeout(this._initialStartTimeout);
      this._initialStartTimeout = null;
    }
  }

  _setStyle(element: HTMLDivElement, styles: Record<string, unknown>) {
    if (!element.style) {
      return;
    }

    Object.assign(element.style, styles);
  }

  _getWrapperStyle(offset: number) {
    return {
      transform: `translate3d(${offset}px, 0px, 0px)`,
      willChange: "transform",
    };
  }

  _getTranslateOffset(offset: number) {
    const { langDirection } = this.props;

    if (offset !== 0 && langDirection === "ltr") {
      return offset - offset * 2;
    }

    return offset;
  }

  _isScrolling() {
    return this._frameId !== null;
  }
}

export default withWidgetPreviewContext(Marquee) as React.FC<Props>;

const MarqueeContainer = styled.div`
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  width: 95%;
`;

const MarqueeWrapper = styled.div`
  display: flex;

  &.is-centered {
    justify-content: center;
  }
`;

const MarqueeContent = styled.div`
  display: flex;
`;
