import { useWidgetDimensions } from "@widget/hooks/useWidgetDimensions";
import { useOptions } from "@widget/queryParams";
import React, { useCallback, useContext, useEffect, useState } from "react";

const TracklistVisibilityContext = React.createContext<boolean>(true);

export const useTracklistVisibility = () =>
  useContext<boolean>(TracklistVisibilityContext);

const WIDTH_XS_BREAKPOINT = 150;
const WIDTH_NORMAL_BREAKPOINT = 250;
const HEIGHT_NORMAL_BREAKPOINT = 250;

type Props = {
  children: React.ReactNode;
  onDisableTracklistOptionChange?: (disabled: boolean) => void;
};

/**
 * Handles the tracklist visibility
 * - if widget size is below certain sizes, will automatically hide the tracklist
 * - if tracklist can be displayed, will apply the tracklist query param option
 */

const TracklistVisibilityProvider: React.FC<Props> = ({
  children,
  onDisableTracklistOptionChange,
}) => {
  const { tracklistVisible: visibleOption } = useOptions();
  const [visible, setVisibility] = useState(visibleOption);

  const dimensions = useWidgetDimensions();

  const measure = useCallback(
    (visibleOptionParam: boolean) => {
      if (!dimensions) return;
      const { width, height } = dimensions;

      let nextVisibility = true;
      if (width < WIDTH_NORMAL_BREAKPOINT && height < 2 * width) {
        nextVisibility = false;
      }

      if (height < HEIGHT_NORMAL_BREAKPOINT && width < 2.5 * height) {
        nextVisibility = false;
      }

      if (width < WIDTH_XS_BREAKPOINT) {
        nextVisibility = false;
      }

      if (nextVisibility === false) {
        setVisibility(false);
      } else {
        setVisibility(visibleOptionParam);
      }

      /**
       * When in generation portal, must disable tracklist option if it doesnt
       * fit the minimal size requirements
       */
      if (onDisableTracklistOptionChange) {
        const disableOption = !nextVisibility;
        onDisableTracklistOptionChange(disableOption);
      }
    },
    [dimensions, onDisableTracklistOptionChange]
  );

  useEffect(() => {
    measure(visibleOption);
  }, [visibleOption, measure]);

  return (
    <TracklistVisibilityContext.Provider value={visible}>
      {children}
    </TracklistVisibilityContext.Provider>
  );
};

export default TracklistVisibilityProvider;
