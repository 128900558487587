import usePrevious from "@hooks/usePrevious";
import useQueryUserData from "@widget/authorization/hooks/useQueryUserData";
import api from "@widget/contexts/api";
import type { StreamLogContextType, WidgetTrack } from "@widget/types";
import { useEffect, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import _get from "lodash/get";

const DEFAULT_USER_ID = 0; // Corresponds to any unlogged user

type Metadata = {
  contextType: StreamLogContextType; // album | playlist etc...
  contextId: string; // albumId, playlistId etc...
  listeningTime: number; // total listening time for the track, in seconds
  timestamp: number; // in milliseconds
};

type logParams = {
  trackId: string;
  metadata: Metadata;
  userId: number;
};

async function streamlogMutation({
  userId,
  trackId,
  metadata,
}: logParams): Promise<void> {
  // API Body is expected to be a FormData
  const body = new FormData();
  body.append("listeningTime", String(metadata.listeningTime));
  body.append("timestamp", String(metadata.timestamp));
  body.append("contextType", metadata.contextType);
  body.append("contextId", metadata.contextId);

  return api.post(`/user/${userId}/streamlog/${trackId}`, body);
}

type Props = {
  track?: WidgetTrack;
  isPlaying: boolean;
};

/**
 * When a track is played, we must log it into our system using
 * streamlog endpoint
 */
export default function useStreamlog({ track, isPlaying }: Props) {
  const { data: userData } = useQueryUserData();
  const trackId = track?.id;
  const prevTrackId = usePrevious(trackId);
  const { mutate: performLog } = useMutation(streamlogMutation);
  const [listeningTime, resetListeningTime] = useListeningTime(isPlaying);

  const userId = userData?.isLogged
    ? _get(userData, "user.USER.USER_ID", DEFAULT_USER_ID)
    : DEFAULT_USER_ID;

  /**
   * When current track changes, log to streamlog
   */
  useEffect(() => {
    if (track && trackId && prevTrackId && trackId !== prevTrackId) {
      if (listeningTime > 0) {
        const { contextId, contextType } = track;

        if (contextType !== "show" && contextType !== "episode") {
          performLog({
            userId,
            trackId,
            metadata: {
              contextId,
              contextType,
              listeningTime,
              timestamp: Math.round(Date.now() / 1000),
            },
          });
        }

        resetListeningTime();
      }
    }
  }, [
    userId,
    listeningTime,
    performLog,
    prevTrackId,
    resetListeningTime,
    track,
    trackId,
  ]);
}

function useListeningTime(isPlaying: boolean): [number, () => void] {
  const listeningTime = useRef(0);
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isPlaying) {
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          listeningTime.current += 1;
        }, 1000);
      }
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, [isPlaying]);

  function reset() {
    listeningTime.current = 0;
  }

  return [listeningTime.current, reset];
}
