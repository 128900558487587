import { useEffect, useState } from "react";
import _set from "lodash/set";
import api from "@widget/contexts/api";
import { useErrorContext } from "@widget/components/ErrorProvider";
import { translate } from "@deezer/i18n";
import {
  isOffline,
  handleOfflineError,
  mapResponseErrorToModalError,
} from "../utils/errorHandlers";

type Props = {
  queryToken: () => Promise<string | void>;
};

const MAX_RETRY = 3;

const useApiErrorInterceptor = ({ queryToken }: Props) => {
  const setError = useErrorContext();
  const [retryNb, setRetryNb] = useState(0);

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      undefined,
      async (error) => {
        if (isOffline(error)) {
          return handleOfflineError(error);
        }

        const modalError = await mapResponseErrorToModalError(error);
        if (modalError) {
          // If an ajax call fails because the token is no more valid
          // 1- Update the token
          // 2- Retry the call
          if (modalError.label === "TOKEN_INVALID") {
            if (retryNb < MAX_RETRY) {
              setRetryNb((r) => r + 1);
              const accessToken = await queryToken();
              _set(
                error,
                "config.headers.Authorization",
                `Bearer ${accessToken}`
              );
              return api.request(error.config);
            }
            setError({
              label: translate(
                "errormessage_text_erroshasoccurredpleasetryagain_widget"
              ),
              reloadCTA: true,
            });
            return Promise.reject(error);
          }
          setError(modalError);
        }

        return Promise.reject(error);
      }
    );

    return () => api.interceptors.response.eject(interceptor);
  }, [queryToken, retryNb, setError]);
};

export default useApiErrorInterceptor;
