import { useQuery } from "@tanstack/react-query";
import api from "../api";
import type { TrackApiResponse, TracksApiResponse } from "./types";

async function queryTrack(trackId: string): Promise<TrackApiResponse> {
  const { data } = await api.get<TrackApiResponse>(`/track/${trackId}`);

  return data;
}

type TrackQueryKey = [string, string | undefined];
export function useTracks(trackIds: string[]) {
  return useQuery<TracksApiResponse, TrackQueryKey>(
    ["track", ...trackIds],
    async () => {
      const promises = trackIds.map((trackId) => queryTrack(trackId));

      const results = (await Promise.allSettled(promises)) as {
        status: "fulfilled" | "rejected";
        value: TrackApiResponse;
      }[];

      return results
        .filter((result) => result.status === "fulfilled")
        .map((result) => result.value);
    },
    {
      enabled: Boolean(trackIds),
    }
  );
}
