import React from "react";
import { useLoadingScreen } from "@widget/components/LoadingScreen";
import useAuthToken from "../hooks/useAuthToken";
import useApiErrorInterceptor from "../hooks/useApiErrorInterceptor";

type Props = {
  children: React.ReactNode;
};

export const AuthorizationProvider: React.FC<Props> = ({ children }) => {
  const { isAuthorized, queryToken } = useAuthToken();
  useApiErrorInterceptor({ queryToken });
  const isLoading = useLoadingScreen({ isLoading: !isAuthorized });

  if (isLoading) return null;

  return <>{children}</>;
};
