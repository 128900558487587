import { useWidgetDimensions } from "@widget/hooks/useWidgetDimensions";
import { css } from "styled-components";

type Props = {
  enabled: boolean;
  tracklistVisible?: boolean;
};

function useLargePlayerCss({ tracklistVisible, enabled }: Props) {
  const widgetDimensions = useWidgetDimensions();

  if (!enabled) return css``;

  if (tracklistVisible) {
    return css`
      width: ${`${widgetDimensions?.height}px`};
      min-width: ${`${widgetDimensions?.height}px`};
      max-width: ${`${widgetDimensions?.height}px`};
    `;
  }

  return css``;
}

export default useLargePlayerCss;
