import React from "react";
import styled, { css, useTheme } from "styled-components";
import type { WidgetTrack } from "@widget/types";
import { useTrackDisabledContext } from "@widget/player/contexts/TrackDisabledContext";
import CoverPlayPause from "./CoverPlayPause";
import useTrackItemHover from "../hooks/useTrackItemHover";

type Props = {
  track: WidgetTrack;
  index: number;
  isPlaying: boolean;
  isCurrent: boolean;
  onTrackItemCtrlClick: () => void;
  compact?: boolean;
};

const TrackListItem: React.FC<Props> = ({
  track,
  isPlaying,
  isCurrent,
  onTrackItemCtrlClick,
  compact,
  ...props
}) => {
  const [disabledTracks] = useTrackDisabledContext();
  const [hovering, hoveringEventProps] = useTrackItemHover();
  const theme = useTheme();

  if (!track.tracklistItemMeta) return null;
  const { title, subtitle } = track.tracklistItemMeta;

  const disabled = disabledTracks[track.id];

  return (
    <Container
      onClick={disabled ? undefined : onTrackItemCtrlClick}
      onKeyPress={disabled ? undefined : onTrackItemCtrlClick}
      role="button"
      tabIndex={disabled ? undefined : 0}
      $disabled={disabled}
      $hovering={hovering}
      {...props}
      {...hoveringEventProps}
    >
      <CoverPlayPause
        track={track}
        isPlaying={isPlaying}
        isCurrent={isCurrent}
        disabled={disabled}
        hovering={hovering}
      />

      <Description $compact={Boolean(compact)}>
        <Title
          $isCurrent={isCurrent}
          $disabled={disabled}
          color={theme.name === "light" ? "#9333E8" : "#D4A3FF"}
          data-testid="widget-tracklist-item-title"
        >
          {title.label}
        </Title>
        {subtitle ? (
          <SubTitle
            $disabled={disabled}
            data-testid="widget-tracklist-item-subtitle"
          >
            {subtitle.label}
          </SubTitle>
        ) : null}
      </Description>
    </Container>
  );
};

type HoveringElementProps = {
  $disabled: boolean;
  $hovering?: boolean;
};

const Container = styled.li<HoveringElementProps>`
  overflow: hidden;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  padding: ${({ theme }) => `${theme.spacing[7]} ${theme.spacing[15]}`};
  background-color: ${({ theme, $hovering }) =>
    $hovering ? theme.colors.backgroundHover : theme.colors.background};
  display: flex;
  align-items: end;
  width: 100%;
  box-sizing: border-box;
`;

type CompactProps = {
  $compact: boolean;
};

const Description = styled.div<CompactProps>`
  margin: ${({ theme }) => theme.spacing[0]};
  margin-left: ${({ theme }) => theme.spacing[10]};
  font-size: ${({ theme }) => theme.fontSize.sm};
  width: 100%;

  display: flex;
  flex-direction: column;
  line-height: 1.2;

  ${({ $compact }) =>
    $compact &&
    css`
      display: block;
    `}
`;

type TitleProps = {
  $isCurrent: boolean;
  color: string;
} & HoveringElementProps;

const Title = styled.div<TitleProps>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.disabled : theme.colors.text};
  min-width: 0;
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  line-height: 1.2;

  ${({ $isCurrent, color, $disabled }) =>
    $isCurrent &&
    !$disabled &&
    css`
      color: ${color};
    `}

  /**
  * Will make the colored text much more comfortable to read on dark mode
  */
  ${({ theme }) =>
    theme.name === "dark" &&
    css`
      filter: brightness(130%);
      font-weight: 300;
    `}
`;

const SubTitle = styled.div<HoveringElementProps>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.disabledSecondary : theme.colors.textSecondary};
  min-width: 0;
  display: block;
  font-weight: 300;
`;

export default TrackListItem;
