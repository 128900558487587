import { translate } from "@deezer/i18n";
import { useErrorContext } from "@widget/components/ErrorProvider";
import { useLoadingScreen } from "@widget/components/LoadingScreen";
import React, { useEffect, useState } from "react";
import initI18n from "../i18n/initI18n";

type Props = {
  children: React.ReactNode;
};

const I18nProvider: React.FC<Props> = ({ children }) => {
  const setError = useErrorContext();
  const [isLoading, setLoading] = useState(true);
  useLoadingScreen({ isLoading });

  useEffect(() => {
    initI18n()
      .then(() => setLoading(false))
      .catch(() =>
        setError({
          label: translate(
            "errormessage_text_erroshasoccurredpleasetryagain_widget"
          ),
        })
      );
  }, [setError]);

  if (isLoading) return null;

  return <>{children}</>;
};

export default I18nProvider;
