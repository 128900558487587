import qs from "qs";
import { PlayerFormat } from "@widget/player/types";
import useLocationSearch from "@hooks/useLocationSearch";

export const DEFAULT_APP_ID = "430922";

type BoolQP = "true" | "false";

export type QueryParams = {
  tracklist?: BoolQP;
  format?: PlayerFormat;
  app_id?: string;
  idType?: "upc";
  radius?: BoolQP;
  /**
   * Note: requires a custom APP_ID to work
   * Disables "publicity" when playing a track, required by some partners
   */
  hideRedirectModal?: BoolQP;

  /**
   * Note: requires a custom APP_ID to work
   * This one is useful for partners who requires to disable sharing
   * a track or deezer user interactions.
   */
  hideExtraActions?: BoolQP;
  autoplay?: "1" | undefined;
};

type Options = {
  tracklistVisible: boolean;
  format: PlayerFormat;
  app_id: string;
  idType?: "upc";
  radius: boolean;
  autoplay: boolean;
  hideRedirectModal: boolean;
  hideExtraActions: boolean;
};

export const useOptions = (): Options => {
  const url = useLocationSearch();

  const {
    tracklist,
    format,
    app_id = DEFAULT_APP_ID,
    idType,
    radius,
    autoplay,
    hideRedirectModal,
    hideExtraActions,
  }: QueryParams = qs.parse(url, {
    ignoreQueryPrefix: true,
  });

  return {
    tracklistVisible: tracklist !== "false",
    format: format || PlayerFormat.Auto,
    app_id,
    radius: radius !== "false",
    autoplay: autoplay === "1",
    hideRedirectModal:
      hideRedirectModal === "true" && app_id !== DEFAULT_APP_ID,
    hideExtraActions: hideExtraActions === "true" && app_id !== DEFAULT_APP_ID,
    idType,
  };
};
