import useQueryUserData from "@widget/authorization/hooks/useQueryUserData";
import api from "@widget/contexts/api";
import type { TrackApiResponse } from "@widget/contexts/track/types";
import type { ApiResponse } from "@widget/contexts/types";
import { useQuery } from "@tanstack/react-query";

export const QUERY_LIKED_TRACKS_LIST_KEY = "liked-tracks-list";

type Response = ApiResponse<unknown, TrackApiResponse["data"]>;

async function query(userId: number) {
  const { data } = await api.get<Response>(`/user/${userId}/loved-tracks`);

  return data;
}

export function useQueryUserLikedTracksList() {
  const { data: userData } = useQueryUserData();

  return useQuery(
    [QUERY_LIKED_TRACKS_LIST_KEY],
    () => query(userData?.user?.USER?.USER_ID as number),
    {
      enabled:
        Boolean(userData?.isLogged) && Boolean(userData?.user?.USER?.USER_ID),
    }
  );
}
