import { useState, useEffect, useCallback, useRef } from "react";
import type { WidgetTrack } from "@widget/types";
import usePrevious from "@hooks/usePrevious";
import { useOptions } from "@widget/queryParams";
import { useTrackDisabledContext } from "../contexts/TrackDisabledContext";
import useAudioPlayerLoadingState from "./useAudioPlayerLoadingState";

type Props = {
  track?: WidgetTrack;
  onNextTrack: () => void;
  onCentralBtnClick: () => void;
  isPlaying: boolean;
};

const useAudioPlayer = ({
  isPlaying,
  onNextTrack,
  onCentralBtnClick,
  track,
}: Props) => {
  const { autoplay } = useOptions();
  const [player, setPlayer] = useState<HTMLAudioElement | null>(null);
  const [currentTime, updateCurrentTime] = useState(0);
  const prevTrackId = usePrevious(track?.id);
  const [touched, setTouched] = useState(false); // true if user has interacted with the widget, false instead
  const onError = useAudioPlayerError({ track, onNextTrack });
  const isLoading = useAudioPlayerLoadingState(player);

  const handlePostMessage = useCallback(
    (event: MessageEvent) => {
      if (!player) return;
      const { action } = event.data;
      switch (action) {
        case "play":
          player.play();
          break;
        case "pause":
          player.pause();
          break;
        default:
          break;
      }
    },
    [player]
  );

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [handlePostMessage]);

  /**
   * --> https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
   */
  const runPlay = useCallback(() => {
    setTouched(true);

    if (!player || !isPlaying) return;
    const playPromise = player.play();
    if (playPromise !== undefined) {
      playPromise.catch(() => {
        /**
         * Ignore error, but avoids firing a sentry log
         * We do this for now because in trying to manage the error
         * we introduced functional issues, while without handling it
         * the QA passes just fine.
         */
      });
    }
    window.parent.postMessage({ action: "play" }, "*");
  }, [isPlaying, player]);

  const runPause = useCallback(() => {
    if (!player) return;
    player.pause();
    window.parent.postMessage({ action: "pause" }, "*");
  }, [player]);

  /**
   * This effect manages the transition when song changes after user choose a
   * different track than the current one in the tracklist
   */
  useEffect(() => {
    if (!track || !player) return;
    if (track.id !== prevTrackId) {
      runPause();
      player.currentTime = 0;
      updateCurrentTime(0);
      player.load();
      runPlay();
    }
  }, [isPlaying, player, prevTrackId, runPause, runPlay, track]);

  useEffect(() => {
    if (isPlaying) {
      runPlay();
    } else {
      runPause();
    }
  }, [isPlaying, player, runPause, runPlay]);

  /**
   * This effect lowers the volume of the player
   */
  useEffect(() => {
    if (player) {
      player.volume = 0.6;
    }
  }, [player]);

  useEffect(() => {
    if (player && autoplay && onCentralBtnClick && !isPlaying && !touched) {
      onCentralBtnClick();
    }
  }, [autoplay, isPlaying, onCentralBtnClick, player, touched]);

  const onTimeUpdate = () => {
    updateCurrentTime(player?.currentTime || 0);
  };

  const onEnded = () => {
    if (!player) return;
    onNextTrack();
    player.currentTime = 0;
    updateCurrentTime(0);
  };

  const onTimeChange = (newTime: number) => {
    if (!player) return;

    player.currentTime = newTime;
    updateCurrentTime(player.currentTime);
  };

  const onSeekBackward = () => {
    if (!player) return;

    const nextTime = player.currentTime - 15;

    if (nextTime < 0) {
      onTimeChange(0);
    } else {
      onTimeChange(nextTime);
    }
  };

  const onSeekForward = () => {
    if (!player) return;

    const nextTime = player.currentTime + 30;

    if (nextTime > player.duration) {
      onEnded();
    } else {
      onTimeChange(nextTime);
    }
  };

  return {
    isPlaying,
    isLoading,
    currentTime,
    touched,
    playerProps: {
      onTimeUpdate,
      onEnded,
      setPlayer,
      onError,
      autoPlay: autoplay,
    },
    progressBarProps: {
      onTimeChange,
      currentTime,
      player,
    },
    actionBarProps: {
      onSeekForward,
      onSeekBackward,
    },
  };
};

export default useAudioPlayer;

type UseAudioPlayerErrorProps = {
  track?: WidgetTrack;
  onNextTrack: () => void;
};

const useAudioPlayerError = ({
  track,
  onNextTrack,
}: UseAudioPlayerErrorProps) => {
  const [, setDisabledTrack] = useTrackDisabledContext();
  /**
   * We implemented a timeout to avoid skipping to quicly a track that is not
   * readable, because when there are multiple unreadable tracks after the other
   * making it instant is quite disturbing
   */
  const timeoutRef = useRef<NodeJS.Timeout>();

  const onError = (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    if (!track) return;
    const error = (e.target as any).error as any; // eslint-disable-line
    if (error.code === error.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      if (track.id) {
        timeoutRef.current = setTimeout(() => {
          setDisabledTrack({ [track.id]: true });
          onNextTrack();
        }, 400);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return onError;
};
