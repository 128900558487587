import React from "react";
import styled from "styled-components";

type TrackHandleProps = {
  $percent: number;
  $visible: boolean;
  $pBarVisible?: boolean;
  $height: number;
};

const TrackHandleButton = styled.button<Omit<TrackHandleProps, "$percent">>`
  position: absolute;
  top: 0;
  background-color: #fff;
  border: ${({ theme }) => theme.borderWidth[0]};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  width: ${({ $height }) => $height}px;
  height: ${({ $height }) => $height}px;
  margin-left: -${({ $height }) => $height / 2}px;
  opacity: ${({ $visible }) => ($visible ? "1" : "0")};
  padding: ${({ theme }) => theme.spacing[0]};

  &:hover {
    cursor: pointer;
  }

  &.focus-visible {
    opacity: 1;
  }
`;

/**
 * Note: we use a style inline object instead of styled template string, because
 * the values frequently changes ! This is intentional, and not a mistake.
 *
 * When using only styled component, it raises a warning in console:
 *
 * > Consider using the attrs method, together with a style object for frequently changed styles.
 */
const TrackHandle: React.FC<
  TrackHandleProps & React.HTMLAttributes<HTMLButtonElement>
> = ({ $percent, $pBarVisible, ...props }) => {
  // Avoids trackhandle to be focusable if not intended to be visible
  const tabIndex = $pBarVisible ? 0 : -1;
  return (
    <TrackHandleButton
      {...props}
      style={{
        left: `${$percent}%`,
      }}
      tabIndex={tabIndex}
    >
      <span className="sr-only">Track Handle</span>
    </TrackHandleButton>
  );
};

export default TrackHandle;
