import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "/api-platform/"
      : "https://api.deezer.com/platform/generic",
});

export const deezerApi = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? "/api" : "https://api.deezer.com",
});

export default api;
