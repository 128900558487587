import React from "react";
import { createGlobalStyle } from "styled-components";
import { WidgetDimensionsContext } from "@widget/hooks/useWidgetDimensions";
import { AuthorizationProvider } from "@widget/authorization/components/AuthorizationProvider";
import ErrorProvider from "@widget/components/ErrorProvider";
import TrackUnavailableProvider from "@widget/player/contexts/TrackDisabledContext";
import useDimensions from "@hooks/useDimensions";
import TracklistVisibilityProvider from "@widget/components/TracklistVisibilityProvider";
import StaticAssetsProvider from "@widget/components/StaticAssetsProvider";
import { LoadingScreenProvider } from "@widget/components/LoadingScreen";
import FullWidthDiv from "./FullWidthDiv";
import I18nProvider from "./I18nProvider";
import ThemeProvider from "./ThemeProvider";
import RTLProvider from "./RTLProvider";

const GlobalStyle = createGlobalStyle`
  #main-provider {
    color: ${({ theme }) => theme.colors.text};
    background-color: transparent;
    font-family: ${({ theme }) => theme.fontFamily.inter};
  }
`;

type Props = {
  children: React.ReactNode;
};

const Provider: React.FC<Props> = ({ children }) => {
  const [setRef, dimensions] = useDimensions();

  return (
    <RTLProvider>
      <ThemeProvider>
        <FullWidthDiv
          ref={setRef}
          id="main-provider"
          data-testid="widget-container"
        >
          <WidgetDimensionsContext.Provider value={dimensions}>
            <GlobalStyle />
            <LoadingScreenProvider>
              <ErrorProvider>
                <I18nProvider>
                  <AuthorizationProvider>
                    <TrackUnavailableProvider>
                      <TracklistVisibilityProvider>
                        <StaticAssetsProvider>{children}</StaticAssetsProvider>
                      </TracklistVisibilityProvider>
                    </TrackUnavailableProvider>
                  </AuthorizationProvider>
                </I18nProvider>
              </ErrorProvider>
            </LoadingScreenProvider>
          </WidgetDimensionsContext.Provider>
        </FullWidthDiv>
      </ThemeProvider>
    </RTLProvider>
  );
};

export default Provider;
