/**
 * Transform a locale to a slug.
 * fr-FR will return fr
 * fr-CA will return fr-CA
 * @param {string} locale
 * @return string
 */
export default function getSlugFromLocale(locale: string): string {
  const [lang, region] = locale.split("-");

  return lang === region.toLocaleLowerCase() ? lang : locale;
}
