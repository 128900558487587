import { useCallback, useEffect, useRef, useState } from "react";

const useDarkMatchMedia = () => {
  const [matches, setMatches] = useState(() => {
    if (!hasWindow()) return undefined;
    let darkMatches = false;
    try {
      darkMatches = window.matchMedia("(prefers-color-scheme: dark)").matches;
    } catch (e) {
      // Do nothing
    }
    return darkMatches;
  });
  const themeListener = useRef<(e: MediaQueryListEvent) => void>();

  const cleanup = useCallback(() => {
    if (themeListener.current && hasWindow()) {
      /**
       * Safari on iOS 13 only supports removeListener
       */
      try {
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .removeEventListener("change", themeListener.current);
      } catch (e1) {
        try {
          window
            .matchMedia("(prefers-color-scheme: dark)")
            .removeListener(themeListener.current);
        } catch (e2) {
          // Do nothing, let the default theme
        }
      }
    }
  }, []);

  useEffect(() => {
    if (hasWindow()) {
      themeListener.current = (e) => {
        setMatches(e.matches);
      };

      /**
       * Safari on iOS 13 only supports addListener
       */
      try {
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .addEventListener("change", themeListener.current);
      } catch (e1) {
        try {
          window
            .matchMedia("(prefers-color-scheme: dark)")
            .addListener(themeListener.current);
        } catch (e2) {
          // Do nothing, let the default theme
        }
      }
    }

    return () => {
      cleanup();
    };
  }, [cleanup]);

  return { matches, cleanup };
};

export default useDarkMatchMedia;

const hasWindow = () => typeof window !== "undefined";
