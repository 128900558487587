/* eslint-disable @typescript-eslint/no-explicit-any */
import { useWidgetDimensions } from "@widget/hooks/useWidgetDimensions";
import { useState, useEffect, useCallback } from "react";

type ProgressBarRef = React.MutableRefObject<HTMLDivElement>;

/**
 * Takes the progress bar DOM element, and returns the corresponding MIN and MAX
 * horizontal coordinates range the track handle must respect.
 */
function useMinMax(progressBarContainerRef: ProgressBarRef) {
  const widgetDimensions = useWidgetDimensions();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const updateMinMax = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (!progressBarContainerRef.current) return;

      const dimensions =
        progressBarContainerRef.current.getBoundingClientRect();
      setMin(dimensions.x);
      setMax(dimensions.x + dimensions.width);
    });
  }, [progressBarContainerRef]);

  // Run once
  useEffect(() => {
    updateMinMax();
  }, [updateMinMax]);

  // Listen for widget dimensions changes, that will need to recompute values
  useEffect(() => {
    if (widgetDimensions) {
      updateMinMax();
    }
  }, [updateMinMax, widgetDimensions]);

  return [min, max];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getClientXPosition(event: any) {
  if (event.changedTouches) {
    return event.changedTouches[0].clientX;
  }

  return event.clientX;
}

function useProgressBarPercent(progressBarContainerRef: ProgressBarRef) {
  const [min, max] = useMinMax(progressBarContainerRef);

  const mapMoveEventToProgressPercent = useCallback(
    (event: any) => {
      const total = max - min;
      const x = getClientXPosition(event);
      let percent: number;

      if (x <= min) {
        percent = 0;
      } else if (x >= max) {
        percent = 100;
      } else {
        const current = x - min;
        percent = (current * 100) / total;
      }

      return percent;
    },
    [max, min]
  );

  return mapMoveEventToProgressPercent;
}


export default useProgressBarPercent;
