import React, { useState } from "react";
import styled, { css } from "styled-components";
import type { WidgetTrack } from "@widget/types";
import ImageFallback from "@widget/components/ImageFallback";
import buildConfig from "build-config.json";

type Props = {
  track: WidgetTrack;
  isPlaying: boolean;
  isCurrent: boolean;
  disabled: boolean;
  hovering: boolean;
};

const CoverPlayPause: React.FC<Props> = ({
  isPlaying,
  isCurrent,
  track,
  disabled,
  hovering,
}) => {
  let CtrlIcon = isPlaying ? PauseIcon : PlayIcon;

  if (!isCurrent) {
    CtrlIcon = PlayIcon;
  }

  const showOverlay = (isCurrent || hovering) && !disabled;

  return (
    <Container
      $disabled={disabled}
      data-testid="widget-tracklist-item-cover-play"
    >
      <CtrlButton
        $isPlaying={isPlaying}
        $trackNbStyle={!hovering && !isCurrent}
      >
        <Cover
          src={track.cover}
          alt="track-cover"
          fallback="/images/empty-cover.jpg"
          data-testid="widget-tracklist-item-cover-img"
        />
        <span className="sr-only">{isPlaying ? "Pause" : "Play"}</span>
        {showOverlay ? (
          <Overlay>
            <CtrlIcon />
          </Overlay>
        ) : null}
      </CtrlButton>
    </Container>
  );
};

export default CoverPlayPause;

const SIZE = "34px";

type ContainerProps = {
  $disabled: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.3;
    `}
`;

const Cover = styled(ImageFallback)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius[5]};
  margin: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[10]}`};
  box-shadow: 0 2px 5px 0px rgb(0 0 0 / 20%);
  margin: 0;
`;

const trackNbStyle = css`
  color: ${({ theme }) => theme.colors.textSecondaryLighter};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  width: ${SIZE};
  height: ${SIZE};
  background-color: transparent;
  box-shadow: none;
`;

type CtrlButtonProps = {
  $isPlaying: boolean;
  $trackNbStyle: boolean;
};

const CtrlButton = styled.div<CtrlButtonProps>`
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing[0]};
  font-weight: ${({ theme }) => theme.fontWeight.normal};

  border: ${({ theme }) => theme.borderWidth[0]};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  width: ${SIZE};
  height: ${SIZE};
  box-shadow: 0 2px 5px 1px rgb(0 0 0 / 20%);
  padding: 0;

  ${({ $isPlaying }) =>
    !$isPlaying &&
    css`
      & > svg {
        margin-left: ${({ theme }) => theme.spacing[2]};
      }
    `}

  ${({ $trackNbStyle }) => $trackNbStyle && trackNbStyle}
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 42%);
  position: absolute;
  border-radius: ${({ theme }) => theme.borderRadius[5]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div`
  width: 15px;
  height: 15px;
`;

function PauseIcon() {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <IconContainer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 14 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 0H0V20H5V0ZM14 0H9V20H14V0Z"
            fill="white"
          />
        </svg>
      ) : (
        <img
          src={`${buildConfig.host_assets_ssl}/images/equalizer-playing.gif`}
          alt="equalizer-playing"
        />
      )}
    </IconContainer>
  );
}

function PlayIcon() {
  return (
    <IconContainer>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginLeft: 1 }}
      >
        <path d="M0 0L18 10L0 20V0Z" fill="white" />
      </svg>
    </IconContainer>
  );
}
