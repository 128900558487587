import { useEffect } from "react";

/**
 * Room for improvement in using a generic to type "callback"
 * Tried for an hour and decided to move on at this point :)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useWindowEvent = (event: string, callback: any) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export default useWindowEvent;
