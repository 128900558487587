import ExternalLink from "@components/ExternalLink";
import React from "react";
import qs from "qs";
import ShareLogoContainer from "./ShareLogoContainer";
import { FACEBOOK_APP_ID } from "./constants";

type Props = {
  contentUrl: string;
};

const FacebookShare: React.FC<Props> = ({ contentUrl }) => {
  const params = {
    app_id: FACEBOOK_APP_ID,
    display: "page",
    href: contentUrl,
    redirect_uri: contentUrl,
  };

  return (
    <ExternalLink
      to={`https://www.facebook.com/dialog/share?${qs.stringify(params)}`}
    >
      <FacebookLogo />
    </ExternalLink>
  );
};

export default FacebookShare;

function FacebookLogo() {
  return (
    <ShareLogoContainer>
      <svg
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="facebook-share"
        height="100%"
        width="100%"
      >
        <title id="facebook-share">Share to facebook</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 11C22 4.92534 17.0747 0 11 0C4.92534 0 0 4.92534 0 11C0 16.4899 4.02198 21.0409 9.28142 21.867V14.1805H6.48769V11H9.28142V8.57599C9.28142 5.81939 10.9242 4.29569 13.4364 4.29569C14.6398 4.29569 15.899 4.5107 15.899 4.5107V7.21782H14.5114C13.1456 7.21782 12.7186 8.06552 12.7186 8.9364V10.9999H15.769L15.2817 14.1804H12.7185V21.867C17.9779 21.0423 21.9999 16.4914 21.9999 10.9999L22 11Z"
          fill="#1977F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2818 14.1803L15.7691 10.9999H12.7187V8.93636C12.7187 8.06701 13.1441 7.21778 14.5115 7.21778H15.8991V4.51067C15.8991 4.51067 14.64 4.29565 13.4365 4.29565C10.9243 4.29565 9.28152 5.81783 9.28152 8.57595V11H6.48779V14.1804H9.28152V21.867C9.84147 21.955 10.4154 22 11.0001 22C11.5848 22 12.1587 21.9535 12.7187 21.867V14.1804H15.2819L15.2818 14.1803Z"
          fill="#FEFEFE"
        />
      </svg>
    </ShareLogoContainer>
  );
}
