import { useCallback, useRef } from "react";
import _debounce from "lodash/debounce";
import type { UseMutateFunction } from "@tanstack/react-query";
import useQueryUserData from "@widget/authorization/hooks/useQueryUserData";
import type { MutateLikeParams } from "../../types";

type Props = {
  contentId?: string;
  onUnloggedUserToggle: VoidFunction;
  mutate: UseMutateFunction<boolean, unknown, MutateLikeParams, unknown>;
  localToggle: (isLiking: boolean) => void;
  isLiking?: boolean;
};

/**
 * This hook has several usages:
 * - If user is not logged in, toggling will call `onUnloggedUserToggle` that
 * will prompt a redirection modal to tell user to go on deezer.com to perform the action
 * - Optimistic update of the like value locally (localToggle), to have a snappy UI even when connectivity
 * is bad
 * - Protects spamming like button by debouncing the real mutation
 */
const useToggleLike = ({
  onUnloggedUserToggle,
  mutate,
  localToggle,
  isLiking,
  contentId,
}: Props) => {
  const { data: userData } = useQueryUserData();
  const debouncedLike = useRef(
    _debounce(mutate, 200, { leading: true, trailing: true })
  );

  const isLogged = userData?.isLogged;

  const toggleLike = useCallback(async () => {
    if (!contentId) return;
    if (!isLogged) {
      onUnloggedUserToggle();
    } else {
      localToggle(!isLiking);
      await debouncedLike.current({ contentId, isLiking: !isLiking });
    }
  }, [contentId, isLogged, onUnloggedUserToggle, localToggle, isLiking]);

  return toggleLike;
};

export default useToggleLike;
