import { useQuery } from "@tanstack/react-query";
import type { AxiosRequestConfig } from "axios";
import api from "../api";

type Collection = {
  name: "album" | "playlist" | "podcast";
  id: string | number | undefined;
};

async function queryCollection<T>(
  collection: Collection,
  axiosConfig?: AxiosRequestConfig
): Promise<T> {
  const url = `/${collection.name}/${collection.id}`;
  const { data } = await api.get(url, axiosConfig);

  if (data.error) {
    throw new Error(data.error);
  }

  return data;
}

type QueryKey = [Collection["name"], Collection["id"]];
function useCollectionApi<T>(
  collection: Collection,
  axiosConfig?: AxiosRequestConfig
) {
  return useQuery<T, QueryKey>(
    [collection.name, collection.id],
    () => queryCollection<T>(collection, axiosConfig),
    {
      enabled: Boolean(collection.id),
    }
  );
}

export default useCollectionApi;
