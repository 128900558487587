import FullWidthDiv from "@components/FullWidthDiv";
import usePrevious from "@hooks/usePrevious";
import WidgetPreviewContext from "@portal/components/WidgetPreviewContext";
import type {
  Dispatch,
  SetStateAction} from "react";
import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import type { ModalErrorType } from "./ModalError";
import ModalError from "./ModalError";
import SizeMeasurerProvider from "./SizeMeasurerProvider";

const ErrorContext = React.createContext<
  Dispatch<SetStateAction<ModalErrorType | undefined>>
>(() => {
  throw new Error("React error context not initialized");
});

export const useErrorContext = () => useContext(ErrorContext);

type Props = {
  children: React.ReactNode;
};

const ErrorProvider: React.FC<Props> = ({ children }) => {
  const [error, setError] = useState<ModalErrorType | undefined>();
  const previewContext = useContext(WidgetPreviewContext);
  const prevPreviewContext = usePrevious(previewContext);

  useEffect(() => {
    if (previewContext?.url !== prevPreviewContext?.url && error) {
      setError(undefined);
    }
  }, [error, prevPreviewContext, previewContext]);

  return (
    <ErrorContext.Provider value={setError}>
      <SizeMeasurerProvider>
        {({ containerRef }) => (
          <FullWidthDiv ref={containerRef}>
            {error ? (
              <ModalError
                error={error}
                onReloadCTAClick={() => {
                  if (previewContext) {
                    setError(undefined);
                  } else {
                    window.location.reload();
                  }
                }}
              />
            ) : (
              <>{children}</>
            )}
          </FullWidthDiv>
        )}
      </SizeMeasurerProvider>
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
