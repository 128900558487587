import qs from "qs";

type Props = {
  appId?: string;
  contextType: string;
  contextId: string;
};

export default function getUTMParams({ appId, contextId, contextType }: Props) {
  const params = {
    utm_source: "widget",
    utm_medium: "widget",
    utm_campaign: appId,
    utm_content: `${contextType}-${contextId}`,
  };

  if (contextType === "artist-top-track") {
    params.utm_content = `artist-${contextId}`;
  }

  return qs.stringify(params);
}
