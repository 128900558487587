import React from "react";
import styled from "styled-components";
import type { Color} from "../contexts/ColorProvider";
import { useColor } from "../contexts/ColorProvider";
import useCentralButtonLoading from "../hooks/useCentralButtonLoading";

type Props = {
  onClick: () => void;
  isPlaying: boolean;
  isLoading: boolean;
};

const CentralButton: React.FC<Props> = ({ onClick, isPlaying, isLoading }) => {
  const { color } = useColor();
  const showLoading = useCentralButtonLoading(isLoading, isPlaying);

  const Icon = isPlaying ? PauseIcon : PlayIcon;

  return (
    <StyledButton
      onClick={onClick}
      color={color}
      data-testid={isPlaying ? "widget-player-pause" : "widget-player-play"}
    >
      <Icon />
      {showLoading && <LoadingIcon />}
      <span className="sr-only">{isPlaying ? "Pause" : "Play"}</span>
    </StyledButton>
  );
};

export default CentralButton;

type StyledButtonProps = {
  color: Color;
};

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  width: 48px;
  height: 48px;
  border: ${({ theme }) => theme.borderWidth[0]};
  color: white;
  font-size: ${({ theme }) => theme.fontSize["2xl"]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  /*! @noflip */
  background: ${({ color }) => color};
  background-size: 200% 200%;
  margin-left: ${({ theme }) => theme.spacing[10]};
  margin-right: ${({ theme }) => theme.spacing[10]};

  &:hover {
    cursor: pointer;
  }
`;

function PauseIcon() {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H0V20H5V0ZM14 0H9V20H14V0Z"
        fill="white"
      />
    </svg>
  );
}

function PlayIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: 4 }}
    >
      <path d="M0 0L18 10L0 20V0Z" fill="white" />
    </svg>
  );
}

function LoadingIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
      }}
    >
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stopColor="#fff" stopOpacity="0" offset="0%" />
          <stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
          <stop stopColor="#fff" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            id="Oval-2"
            stroke="url(#a)"
            strokeWidth="2"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill="#fff" cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  );
}
