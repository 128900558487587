import React, { useEffect } from "react";
import type { WidgetTrack } from "@widget/types";
import useSingleton from "@widget/hooks/useSingleton";
import styled from "styled-components";
import Player from "@widget/player/Player";
import { PlayerFormat } from "@widget/player/types";
import ColorProvider from "@widget/player/contexts/ColorProvider";
import { useLoadingScreen } from "@widget/components/LoadingScreen";
import { translate } from "@deezer/i18n";
import { useErrorContext } from "@widget/components/ErrorProvider";

type Props = {
  isLoading: boolean;
  track?: WidgetTrack;
  allowSeekJumps?: boolean;
};

const SingletonWidget: React.FC<Props> = ({
  track,
  isLoading,
  allowSeekJumps = false,
}) => {
  const { currentTrack, onCentralBtnClick, isPlaying, onNextTrack } =
    useSingleton(track);

  const setError = useErrorContext();

  useLoadingScreen({ isLoading });

  useEffect(() => {
    if (!isLoading && !track) {
      setError({
        label: translate(
          "errormessage_text_contentnotavailableatthemoment_widget"
        ),
      });
    }
  }, [track, isLoading, setError]);

  if (isLoading) return null;

  return (
    <ColorProvider>
      <Container>
        <Player
          format={PlayerFormat.Horizontal}
          track={currentTrack}
          isLoading={isLoading}
          isPlaying={isPlaying}
          onCentralBtnClick={onCentralBtnClick}
          allowQueueCtrl={false}
          onNextTrack={onNextTrack}
          allowSeekJumps={allowSeekJumps}
          tracklistVisible
        />
      </Container>
    </ColorProvider>
  );
};

export default SingletonWidget;

const Container = styled.div`
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    theme.playerRadius &&
    `
      border-radius: ${theme.borderRadius[10]};
    `}
`;
