import { useState } from "react";

function useTrackHandle() {
  const [trackHandleVisible, setTrackHandleVisibility] = useState(false);

  return {
    onMouseEnter: () => setTrackHandleVisibility(true),
    onMouseLeave: () => setTrackHandleVisibility(false),
    trackHandleVisible,
  };
}

export default useTrackHandle;
