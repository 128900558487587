import { useState } from "react";
import Cookies, { type CookieAttributes } from "js-cookie";

export function readCookie(key: string, initialValue?: string) {
  try {
    return Cookies.get(key);
  } catch (error) {
    // If error also return initialValue
    return initialValue;
  }
}

function useCookies(
  key: string,
  initialValue?: string
): [string | undefined, (v: string | null) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() =>
    readCookie(key, initialValue)
  );

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: string | null) => {
    const attributes: CookieAttributes = {
      secure: window.location.hostname !== "localhost",
      sameSite: "Lax",
      domain: window.location.hostname,
    };

    if (value === null) {
      Cookies.remove(key, attributes);
    } else Cookies.set(key, value, attributes);

    setStoredValue(value || undefined);
  };

  return [storedValue, setValue];
}

export default useCookies;
