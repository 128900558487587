import ActionButton from "@widget/player/components/ActionButton";
import React from "react";
import EmptyActionIcon from "@widget/user-actions/components/EmptyActionIcon";
import type { WidgetTrack } from "@widget/types";
import isMobileDevice from "@widget/utils/isMobileDevice";
import styled from "styled-components";

type Props = {
  playerSize: "XS" | "SM" | "MD" | "LG" | "XL";
  touched: boolean;
  toggle: (b: boolean) => void;
  track?: WidgetTrack;
} & React.ComponentProps<typeof ActionButton>;

const ActionShareButton: React.FC<Props> = ({
  touched,
  playerSize,
  toggle,
  track,
  ...buttonProps
}) => {
  if (playerSize === "XS" || !touched) {
    return <EmptyActionIcon />;
  }

  const handleClick = () => {
    if (isMobileDevice() && navigator.share) {
      navigator
        .share({
          title: track?.title,
          url: track?.sharingMenuMeta.redirectUrl,
        })
        .then(() => {
          // console.log("share success");
        })
        .catch(() => {
          // console.log("share error");
        });
    } else {
      toggle(true);
    }
  };

  return (
    <CustomizedActionButton
      data-testid="widget-user-actions-share-button"
      onClick={handleClick}
      {...buttonProps}
    >
      <ShareIcon />
      <span className="sr-only">Share</span>
    </CustomizedActionButton>
  );
};

export default ActionShareButton;

/**
 * We needed to do a custom button for the share, because the icon design gave us
 * is larger that the heart one...
 * Thing is, we need them to take the same width space so that the player stays
 * at the center.
 */
const CustomizedActionButton = styled(ActionButton)`
  padding-left: ${({ $wide, theme }) =>
    $wide ? theme.spacing[28] : theme.spacing[8]};
  padding-right: ${({ $wide, theme }) =>
    $wide ? theme.spacing[28] : theme.spacing[8]};
`;

function ShareIcon() {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6245 21.3116L12.6145 18.9895L12.5987 15.3036C9.92592 15.4908 7.57085 16.4746 5.80656 17.487C4.82163 18.0523 4.03456 18.6186 3.49636 19.0414C3.22763 19.2526 3.022 19.427 2.88586 19.5465C2.81782 19.6062 2.76723 19.6522 2.73489 19.6819C2.71873 19.6968 2.70713 19.7077 2.70021 19.7142L2.69356 19.7205L2.69334 19.7207L2.69314 19.7209L2.69283 19.7212L2.69256 19.7214L0.397949 21.9294L1.01939 18.8048L2.00018 18.9999C2.00018 18.9999 2.4804 18.5378 3.34194 17.9025C5.10787 16.6004 8.47589 14.5708 12.5944 14.3016C12.8258 14.2865 13.0596 14.2769 13.2956 14.2734C13.3948 14.2719 13.4943 14.2714 13.5943 14.2721L13.6086 17.6089L13.6145 18.9852L14.6105 18.0353L17.9227 14.8758L21.2746 11.6886L22.0002 10.9987L21.2757 10.3076L14.6145 3.95376L13.6145 2.9999V4.38187V7.30171C6.50999 8.21637 3.54388 14.2664 2.49394 17.2884C2.13523 18.3209 2.00018 18.9999 2.00018 18.9999C1.01939 18.8048 1.01948 18.8044 1.01958 18.8039L1.01981 18.8027L1.02043 18.7996L1.02228 18.7906L1.02842 18.7613C1.03363 18.7369 1.04109 18.7027 1.05099 18.6594C1.07077 18.5728 1.10028 18.4495 1.14086 18.2944C1.22199 17.9844 1.34767 17.5463 1.52886 17.0194C1.89039 15.9681 2.47759 14.5495 3.3822 13.0854C5.08848 10.3237 7.9682 7.34018 12.6145 6.44932V2.9999V0.664062L14.3047 2.2763L22.6904 10.2751L23.4502 10.9998L22.6893 11.7233L18.6129 15.5994L18.6117 15.6005L14.3048 19.7088L12.6245 21.3116Z"
        fill="white"
      />
    </svg>
  );
}
