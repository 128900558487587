import ActionButton from "@widget/player/components/ActionButton";
import React from "react";
import styled from "styled-components";
import isMobileDevice from "@widget/utils/isMobileDevice";
import type { WidgetTrack } from "@widget/types";
import CopyToClipboardShare from "./CopyToClipboardShare";
import FacebookShare from "./FacebookShare";
import MessengerShare from "./MessengerShare";
import ShareLogoContainer from "./ShareLogoContainer";
import TwitterShare from "./TwitterShare";
import WhatsAppShare from "./WhatsAppShare";

type Props = {
  onClose: VoidFunction;
  track: WidgetTrack;
  playerSize: "XS" | "SM" | "MD" | "LG" | "XL";
};

const SharingMenu: React.FC<Props> = ({ track, onClose, playerSize }) => {
  const contentUrl = track.sharingMenuMeta.redirectUrl;
  return (
    <Container>
      <Menu>
        <CloseLogo
          onClick={onClose}
          isLarge={playerSize === "XL" || isMobileDevice()}
        />

        <FacebookShare contentUrl={contentUrl} />
        <MessengerShare contentUrl={contentUrl} />
        <WhatsAppShare contentUrl={contentUrl} />
        <TwitterShare contentUrl={contentUrl} />
        <CopyToClipboardShare contentUrl={contentUrl} />
      </Menu>
    </Container>
  );
};

export default SharingMenu;

const Container = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div`
  width: 100%;
  max-width: 280px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 50px;
  height: 50px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
`;

type CloseLogoProps = {
  isLarge: boolean;
} & React.ComponentProps<typeof ShareLogoContainer>;

const CloseLogo: React.FC<CloseLogoProps> = ({ onClick, isLarge }) => (
  <ActionButton
    onClick={onClick}
    style={{ paddingLeft: 0, paddingRight: 0, marginRight: isLarge ? 8 : 0 }}
    $animate={false}
  >
    <ShareLogoContainer size="16px" style={{ marginTop: 2 }}>
      <svg
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00242 7.70928L13.2969 14.0038L14.004 13.2967L7.70953 7.00218L14.0044 0.707348L13.2972 0.000241101L7.00242 6.29507L0.707107 -0.000244141L0 0.706863L6.29531 7.00218L0.000342369 13.2971L0.707449 14.0043L7.00242 7.70928Z"
          fill="#72727D"
        />
      </svg>
    </ShareLogoContainer>
  </ActionButton>
);
