import { useOptions } from "@widget/queryParams";
import useCollectionApi from "../collection/useCollectionApi";
import type { AlbumApiResponse } from "./types";

type Props = {
  albumId: string;
};

const useQueryAlbum = ({ albumId }: Props) => {
  const { idType } = useOptions();
  const queryResult = useCollectionApi<AlbumApiResponse>(
    {
      name: "album",
      id: albumId,
    },
    {
      params: {
        type: idType,
      },
    }
  );

  return { ...queryResult };
};

export default useQueryAlbum;
