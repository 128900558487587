import React from "react";
import type { WidgetTrack } from "@widget/types";
import { StreamLogContextType } from "@widget/types";
import { useParams } from "react-router-dom";
import QueuedWidget from "@widget/formats/QueuedWidget";
import { dzrLink } from "@components/ExternalLink";
import useQueryError from "@widget/hooks/useQueryError";
import { useOptions } from "@widget/queryParams";
import getUTMParams from "@widget/utils/getUTMParams";
import { useTracks } from "./useTracks";
import type { TrackApiResponse } from "./types";

type RouteParams = {
  trackIds: string;
};

const TracksWidget: React.FC = () => {
  const { trackIds = "" } = useParams<RouteParams>();
  const parsedTrackIds = trackIds.split(",");
  const { isLoading, data, isError, error } = useTracks(parsedTrackIds);
  useQueryError({ isError, error });
  const { app_id } = useOptions();
  const utm = getUTMParams({
    appId: app_id,
    contextId: trackIds,
    contextType: "track",
  });

  const tracklist = data
    ?.map((track) => getTracklist(track, utm))
    .filter((track) => track !== undefined) as WidgetTrack[];

  return <QueuedWidget tracklist={tracklist} isLoading={isLoading} />;
};

export default TracksWidget;

export const getTracklist = (
  trackResponse?: TrackApiResponse,
  utm?: string
): WidgetTrack | undefined => {
  if (!trackResponse || !trackResponse.data?.attributes) return undefined;

  const track = trackResponse.data.attributes;

  return {
    type: "track",
    id: trackResponse.data.id,
    cover: track.image.medium,
    readable: true,
    title: track.title,
    audioUrl: "",
    contextType: StreamLogContextType.Track,
    contextId: trackResponse.data.id,
    playerMeta: {
      initial: {
        title: {
          label: track.title,
          redirectUrl: dzrLink("track", trackResponse.data.id, utm),
        },
        subtitle: {
          label: track.artistName,
          redirectUrl: dzrLink("artist", track.artistId, utm),
        },
        cover: track.image.large,
      },
      touched: {
        title: {
          label: track.title,
          redirectUrl: dzrLink("track", trackResponse.data.id, utm),
        },
        subtitle: {
          label: track.artistName,
          redirectUrl: dzrLink("artist", track.artistId, utm),
        },
        cover: track.image.large,
      },
    },
    tracklistItemMeta: {
      title: {
        label: track.title,
      },
      subtitle: {
        label: track.artistName,
        redirectUrl: dzrLink("artist", track.artistId, utm),
      },
    },
    sharingMenuMeta: {
      redirectUrl: dzrLink("track", trackResponse.data.id, utm),
    },
  };
};
