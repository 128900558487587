import { useEffect, useState, useCallback } from "react";

/**
 * This hook handles the buffer track width and position.
 */
function useBuffer(player: HTMLAudioElement | null) {
  const [width, setWidth] = useState(0);
  const [origin, setOrigin] = useState(0);

  const updateBuffer = useCallback(() => {
    if (!player) return;
    let range = 0;
    const buffer = player.buffered;
    const time = player.currentTime;

    if (Number.isNaN(player.duration) || !player.onprogress) {
      return;
    }
    while (!(buffer.start(range) <= time && time <= buffer.end(range))) {
      range += 1;
    }
    const loadStartPercentage = buffer.start(range) / player.duration;
    const loadEndPercentage = buffer.end(range) / player.duration;
    const loadPercentage = loadEndPercentage - loadStartPercentage;
    setOrigin(loadStartPercentage * 100);
    setWidth(loadPercentage * 100);
  }, [player]);

  useEffect(() => {
    if (!player) return;

    player.addEventListener("progress", updateBuffer);

    return () => player?.removeEventListener("progress", updateBuffer); // eslint-disable-line
  }, [player, updateBuffer]);

  return { width, origin };
}

export default useBuffer;
