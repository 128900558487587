import { initTranslation } from "@deezer/i18n";
import getAppLanguage from "./getAppLanguage";
import getLocaleFromSlug from "./getLocaleFromSlug";
import getSlugFromLocale from "./getSlugFromLocale";
import meta from "./locales/metas.json";

async function initI18n(): Promise<void> {
  const appLanguage = getAppLanguage(
    meta.availableLanguages.map(getSlugFromLocale)
  );
  try {
    const localeLanguage = getLocaleFromSlug(appLanguage);
    const translation = await import(
      /* webpackChunkName: "bocal-[request]" */ `./locales/${localeLanguage}`
    );
    initTranslation(appLanguage, translation);
  } catch (e) {
    throw new Error(`Could not load translations for language ${appLanguage}`);
  }
}

export default initI18n;
