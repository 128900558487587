import ActionButton from "@widget/player/components/ActionButton";
import { usePlayerModal } from "@widget/player/components/PlayerModal";
import React, { useCallback, useEffect, useState } from "react";
import { useClipboard } from "use-clipboard-copy";
import { translate } from "@deezer/i18n";
import ShareLogoContainer from "./ShareLogoContainer";

type Props = {
  contentUrl: string;
};

const CopyToClipboardShare: React.FC<Props> = ({ contentUrl }) => {
  const [, setPlayerModal] = usePlayerModal();

  const hasClipboardPermission = useHasClipboardWritePermission();

  const showRedirectionModal = useCallback(() => {
    setPlayerModal({
      isVisible: true,
      text: translate("infomessage_text_linkcopiedtoyourclipboard_widget"),
    });
  }, [setPlayerModal]);

  const clipboard = useClipboard();

  if (!clipboard.isSupported || !hasClipboardPermission) return null;

  return (
    <ActionButton
      onClick={() => {
        clipboard.copy();
        showRedirectionModal();
      }}
      style={{ paddingLeft: 0, paddingRight: 0 }}
      $animate={false}
    >
      <input
        ref={clipboard.target}
        value={contentUrl}
        readOnly
        style={{ display: "none" }}
      />
      <CopyToClipboardShareLogo />
    </ActionButton>
  );
};

export default CopyToClipboardShare;

function CopyToClipboardShareLogo() {
  return (
    <div>
      <ShareLogoContainer size="20px">
        <svg
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="copy-to-clipboard"
          height="100%"
          width="100%"
        >
          <title id="copy-to-clipboard">Copy to clipboard</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.58448 10.7647L4.64478 7.70436L5.35189 8.41147L2.29159 11.4718C0.56947 13.1939 0.569471 15.986 2.29159 17.7081C4.01371 19.4302 6.80581 19.4302 8.52793 17.7081L11.5882 14.6478L12.2953 15.3549L9.23503 18.4152C7.12239 20.5279 3.69712 20.5279 1.58448 18.4152C-0.52816 16.3026 -0.528161 12.8773 1.58448 10.7647ZM17.7086 8.52744L14.6485 11.5876L15.3556 12.2947L18.4157 9.23455C20.5284 7.12191 20.5284 3.69664 18.4157 1.584C16.3031 -0.528647 12.8778 -0.528651 10.7652 1.58399L7.70501 4.64414L8.41211 5.35125L11.4723 2.2911C13.1944 0.568981 15.9865 0.568983 17.7086 2.2911C19.4307 4.01322 19.4307 6.80533 17.7086 8.52744ZM6.55734 14.2075L14.2079 6.5569L13.5008 5.84979L5.85023 13.5003L6.55734 14.2075Z"
            fill="#72727D"
          />
        </svg>
      </ShareLogoContainer>
    </div>
  );
}

const supportPermissionsApi =
  typeof navigator !== "undefined" && navigator.permissions;

/**
 * Some browsers (eg: Chrome) require specific permissions to use clipboard-write API
 * So we need to check that those permissions are allowed
 */
const useHasClipboardWritePermission = () => {
  const [hasPermission, setHasPermission] = useState(() => {
    if (!supportPermissionsApi) return true;
    return false;
  });

  useEffect(() => {
    if (!supportPermissionsApi) return;
    const permissionName = "clipboard-write" as PermissionName;
    navigator.permissions
      .query({ name: permissionName })
      .then(function permissionHandler(permissionStatus) {
        if (permissionStatus.state === "granted") {
          setHasPermission(true);
        }

        // eslint-disable-next-line no-param-reassign
        permissionStatus.onchange = function permissionChangeHandler() {
          if (this.state === "granted") {
            setHasPermission(true);
          }
        };
      });
  }, []);

  return hasPermission;
};
