import ActionButton from "@widget/player/components/ActionButton";
import React from "react";
import type { WidgetTrack } from "@widget/types";
import useQueryUserLikeTrack from "../hooks/useQueryUserLikeTrack";
import useMutateTrackLike from "../hooks/useMutateTrackLike";
import LikeIcon from "./LikeIcon";
import useToggleLike from "../hooks/useToggleLike";

type Props = {
  track?: WidgetTrack;
  onUnloggedUserToggle: () => void;
} & React.ComponentProps<typeof ActionButton>;

const ActionLikeTrackButton: React.FC<Props> = ({
  track,
  onUnloggedUserToggle,
  ...buttonProps
}) => {
  const trackId = track?.id;

  const [isLiking, setIsLiking] = useQueryUserLikeTrack(trackId);
  const { mutate } = useMutateTrackLike({
    onSuccess: (nextIsLiking) => {
      setIsLiking(nextIsLiking);
    },
  });

  const toggleLike = useToggleLike({
    isLiking,
    mutate,
    localToggle: setIsLiking,
    onUnloggedUserToggle,
    contentId: trackId,
  });

  return (
    <ActionButton onClick={toggleLike} {...buttonProps}>
      <LikeIcon active={isLiking} />
      <span className="sr-only">
        {isLiking ? "Unlike track" : "Like track"}
      </span>
    </ActionButton>
  );
};

export default ActionLikeTrackButton;
