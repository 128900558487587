import type {
  Dispatch,
  SetStateAction} from "react";
import React, {
  useCallback,
  useContext,
  useState,
} from "react";
import Modal from "@widget/components/Modal";
import ModalButton from "@widget/components/ModalButton";
import _noop from "lodash/noop";
import { useModalSize } from "../../components/ModalSizeContext";

type ModalState = {
  isVisible: boolean;
  text?: string;
  buttonText?: string;
  redirectTo?: string;
};

type Context = [ModalState, Dispatch<SetStateAction<ModalState>>];

const PlayerModalContext = React.createContext<Context>([
  { isVisible: false },
  _noop,
]);

export const usePlayerModal = () => useContext(PlayerModalContext);

type Props = {
  children: React.ReactNode;
};

const PlayerModalProvider: React.FC<Props> = ({ children }) => {
  const playerSize = useModalSize();
  const contextState = useState<ModalState>({
    isVisible: false,
  });

  const [modalState, setModalState] = contextState;

  const { text, buttonText, isVisible, redirectTo } = modalState;

  const closeModal = useCallback(() => {
    setTimeout(() => setModalState(() => ({ isVisible: false })), 100);
  }, [setModalState]);

  return (
    <PlayerModalContext.Provider value={contextState}>
      {children}

      <Modal
        text={text as string}
        isVisible={isVisible}
        closeModal={closeModal}
        transparent
        data-testid="widget-player-admodal"
      >
        {redirectTo && (
          <ModalButton
            to={redirectTo}
            onClick={closeModal}
            $size={playerSize}
            data-testid="widget-player-admodal-cta"
          >
            {buttonText}
          </ModalButton>
        )}
      </Modal>
    </PlayerModalContext.Provider>
  );
};

export default PlayerModalProvider;
