import usePrevious from "@hooks/usePrevious";
import { useOptions } from "@widget/queryParams";
import type { WidgetTrack } from "@widget/types";
import { useRef, useEffect, useReducer } from "react";

type StateType = {
  pendingVisibility: boolean;
  isVisible: boolean;
  trackAdHasPlayed: boolean;
  nextTick: number;
};

type CLOSE_AD_ACTION = {
  type: "CLOSE_AD";
  nextTick: number;
};

type RESET_TRACK_AD_PLAYED_ACTION = {
  type: "RESET_TRACK_AD_PLAYED";
  nextTick: number;
};

type ActionType =
  | {
      type:
        | "START_PENDING_VISIBILITY"
        | "STOP_PENDING_VISIBILITY"
        | "SET_VISIBLE";
    }
  | CLOSE_AD_ACTION
  | RESET_TRACK_AD_PLAYED_ACTION;

const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case "START_PENDING_VISIBILITY": {
      return {
        ...state,
        pendingVisibility: true,
      };
    }
    case "STOP_PENDING_VISIBILITY": {
      return {
        ...state,
        pendingVisibility: false,
        isVisible: false,
      };
    }
    case "SET_VISIBLE": {
      return {
        ...state,
        isVisible: true,
      };
    }
    case "CLOSE_AD": {
      return {
        ...state,
        isVisible: false,
        trackAdHasPlayed: true,
        pendingVisibility: false,
        nextTick: action.nextTick,
      };
    }
    case "RESET_TRACK_AD_PLAYED": {
      return {
        ...state,
        trackAdHasPlayed: false,
        nextTick: action.nextTick,
      };
    }
    default: {
      return state;
    }
  }
};

type Props = {
  touched: boolean;
  isPlaying: boolean;
  trackId?: WidgetTrack["id"];
  trackType?: WidgetTrack["type"];
  isAudioLoading: boolean;
};

const useAdModalVisibility = ({
  touched,
  isPlaying,
  trackId,
  trackType,
  isAudioLoading,
}: Props) => {
  const { hideRedirectModal } = useOptions();
  const FIRST_PROMPT_AFTER_MS = 10000;
  const NEXT_PROMPT_AFTER_MS = trackType === "track" ? -1 : 30000;

  const [state, dispatch] = useReducer(reducer, {
    pendingVisibility: touched && isPlaying,
    isVisible: false,
    trackAdHasPlayed: false,
    nextTick: FIRST_PROMPT_AFTER_MS,
  });

  const { pendingVisibility, isVisible, trackAdHasPlayed, nextTick } = state;

  const timeoutRef = useRef<NodeJS.Timeout>();
  const prevTrackId = usePrevious(trackId);

  /**
   * Pending visibility should prepare AdModal to appear or prevent its apparition
   */
  useEffect(() => {
    /**
     * If modal pending and not visible yet, start the timeout
     * to make it appear after some time (nextTick MS)
     */
    if (
      pendingVisibility &&
      !isVisible &&
      nextTick !== -1 &&
      !hideRedirectModal
    ) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        dispatch({ type: "SET_VISIBLE" });
      }, nextTick);
    }

    /**
     * If modal shouldn't be displayed (user paused the track), reset the timeout
     */
    if (!pendingVisibility && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [pendingVisibility, isVisible, nextTick, hideRedirectModal]);

  /**
   * When trackId changes, we re-arm the modal if it has been closed before
   */
  useEffect(() => {
    if (prevTrackId !== trackId && trackAdHasPlayed) {
      dispatch({
        type: "RESET_TRACK_AD_PLAYED",
        nextTick: FIRST_PROMPT_AFTER_MS,
      });
    }
  }, [prevTrackId, trackAdHasPlayed, trackId]);

  /**
   * When user has asked to play a track and loading has ended
   * ---> prepare the AdModal to show up
   */
  useEffect(() => {
    if (touched && isPlaying && !isAudioLoading) {
      dispatch({ type: "START_PENDING_VISIBILITY" });
    }
  }, [isPlaying, touched, isAudioLoading]);

  /**
   * When the audio is buffering and the modal is about to show up
   * ---> cancel the AdModal
   */
  useEffect(() => {
    if (pendingVisibility && isAudioLoading && !isVisible) {
      dispatch({ type: "STOP_PENDING_VISIBILITY" });
    }
  }, [pendingVisibility, isPlaying, isAudioLoading, isVisible]);

  const closeModal = () => {
    dispatch({ type: "CLOSE_AD", nextTick: NEXT_PROMPT_AFTER_MS });
  };

  return { closeModal, modalVisible: isVisible };
};

export default useAdModalVisibility;
