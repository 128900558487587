import type { FunctionComponent} from "react";
import React, { useState } from "react";
import styled from "styled-components";
import { useSizeMeasurer } from "@widget/components/SizeMeasurerProvider";
import ActionLikeButton from "@widget/user-actions/like/components/ActionLikeButton";
import type { WidgetTrack } from "@widget/types";
import ActionShareButton from "@widget/user-actions/share/components/ActionShareButton";
import SharingMenu from "@widget/user-actions/share/components/SharingMenu";
import { useOptions } from "@widget/queryParams";
import CentralButton from "./CentralButton";
import ActionButton from "./ActionButton";

const WIDTH_LG_BREAKPOINT = 520;
const WIDTH_MD_BREAKPOINT = 410;
const WIDTH_SM_BREAKPOINT = 320;
const WIDTH_XS_BREAKPOINT = 320;

type Props = {
  isPlaying: boolean;
  isLoading: boolean;
  onCentralBtnClick: () => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  onShareClick?: () => void;
  onSeekForward?: () => void;
  onSeekBackward?: () => void;
  track: WidgetTrack;
  touched: boolean;
  contentUrl: string;
};

const ActionBar: FunctionComponent<Props> = ({
  track,
  touched,
  isPlaying,
  isLoading,
  onCentralBtnClick,
  onPrevClick,
  onNextClick,
  onSeekForward,
  onSeekBackward,
  contentUrl,
}) => {
  const { hideExtraActions } = useOptions();
  const playerSize = usePlayerSize();
  const [isShareMenuVisible, toggleShareMenu] = useState(false);

  const showExtraActions = !hideExtraActions;

  return (
    <Container>
      <ActionsContainer>
        {isShareMenuVisible ? (
          <SharingMenu
            track={track}
            onClose={() => toggleShareMenu(false)}
            playerSize={playerSize}
          />
        ) : (
          <>
            {showExtraActions && (
              <ActionShareButton
                touched={touched}
                playerSize={playerSize}
                toggle={toggleShareMenu}
                track={track}
              />
            )}

            <MainActions dir="ltr" compact={playerSize !== "XL"}>
              {onSeekBackward && playerSize === "XL" && (
                <ActionButton
                  onClick={onSeekBackward}
                  data-testid="widget-player-seek-15s-backward"
                >
                  <FifteenSecsBackwardIcon />
                  <span className="sr-only">15 seconds backward</span>
                </ActionButton>
              )}

              {onPrevClick && (
                <ActionButton
                  onClick={onPrevClick}
                  data-testid="widget-player-skip-backward"
                >
                  <SkipBackwardIcon />
                  <span className="sr-only">Skip backward</span>
                </ActionButton>
              )}

              <CentralButton
                isPlaying={isPlaying}
                isLoading={isLoading}
                onClick={onCentralBtnClick}
              />

              {onNextClick && (
                <ActionButton
                  onClick={onNextClick}
                  data-testid="widget-player-skip-forward"
                >
                  <SkipForwardIcon />
                  <span className="sr-only">Skip forward</span>
                </ActionButton>
              )}

              {onSeekForward && playerSize === "XL" && (
                <ActionButton
                  onClick={onSeekForward}
                  data-testid="widget-player-seek-30s-forward"
                >
                  <ThirtySecsForwardIcon />
                  <span className="sr-only">30 seconds forward</span>
                </ActionButton>
              )}
            </MainActions>

            {showExtraActions && (
              <ActionLikeButton
                track={track}
                touched={touched}
                playerSize={playerSize}
                redirectUnloggedTo={contentUrl}
              />
            )}
          </>
        )}
      </ActionsContainer>
    </Container>
  );
};

export default ActionBar;

const usePlayerSize = () => {
  const playerSize = useSizeMeasurer();

  if (!playerSize) return "XL";
  if (playerSize.width > WIDTH_LG_BREAKPOINT) return "XL";
  if (playerSize.width > WIDTH_MD_BREAKPOINT) return "LG";
  if (playerSize.width > WIDTH_SM_BREAKPOINT) return "MD";
  if (playerSize.width > WIDTH_XS_BREAKPOINT) return "SM";
  return "XS";
};

const Container = styled.div`
  width: 100%;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: ${({ theme }) => theme.spacing[10]};
  padding-bottom: ${({ theme }) => theme.spacing[15]};
  min-height: 70px;
`;

const MainActions = styled.div<{ compact: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ theme, compact }) => theme.spacing[compact ? 10 : 15]};
  margin-right: ${({ theme, compact }) => theme.spacing[compact ? 10 : 15]};
`;

function SkipBackwardIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H1V9.6087L18 0V20L1 10.3913V20H0V0Z"
        fill="white"
      />
    </svg>
  );
}

function SkipForwardIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 9.6087L0 0V20L17 10.3913V20H18V0H17V9.6087Z"
        fill="white"
      />
    </svg>
  );
}

function FifteenSecsBackwardIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0.964355L7.96442 4.49989L11.5 8.03542V5.014C11.6616 5.00427 11.8243 4.99933 11.9879 4.99933C16.9148 4.99933 21 9.47784 21 14.3219C21 19.0826 16.9974 22.9993 11.9879 22.9993C7.21828 22.9993 3.36154 19.4488 3.00303 14.9998H2C2.35999 20.0282 6.69415 23.9993 11.9879 23.9993C17.5174 23.9993 22 19.6666 22 14.3219C22 8.97722 17.5174 3.99933 11.9879 3.99933C11.8243 3.99933 11.6616 4.00369 11.5 4.01229V0.964355ZM9.05542 17H10.3826V10.6587H9.05982L7.42066 11.7881V13.0229L8.97632 11.9507H9.05542V17ZM14.3774 17.1626C15.8056 17.1626 16.7592 16.2485 16.7592 14.9038C16.7592 13.6162 15.8847 12.8164 14.7905 12.8164C14.1313 12.8164 13.6655 13.0537 13.4018 13.4536H13.3227L13.4853 11.7485H16.4164V10.6587H12.457L12.1186 14.3896H13.3051C13.5029 14.0161 13.8896 13.7832 14.3906 13.7832C15.0366 13.7832 15.4936 14.2358 15.4936 14.917C15.4936 15.6157 15.0322 16.0771 14.3642 16.0771C13.7753 16.0771 13.3227 15.73 13.2524 15.1938H12.0263C12.0659 16.3276 13.0019 17.1626 14.3774 17.1626Z"
        fill="white"
      />
    </svg>
  );
}

function ThirtySecsForwardIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0.964355L15.0355 4.49989L11.5 8.03542V5.01549C6.80526 5.31205 3 9.64672 3 14.3219C3 19.0826 7.00253 22.9993 12.0121 22.9993C16.7817 22.9993 20.6384 19.4488 20.9969 14.9998H22C21.64 20.0282 17.3058 23.9993 12.0121 23.9993C6.48256 23.9993 2 19.6666 2 14.3219C2 9.14323 6.20843 4.30891 11.5 4.0136V0.964355ZM8.28202 14.2358H9.08622C9.79813 14.2358 10.2376 14.583 10.2376 15.1455C10.2376 15.6948 9.76737 16.0596 9.09501 16.0596C8.41386 16.0596 7.95683 15.7168 7.91727 15.1807H6.64725C6.70438 16.3804 7.67997 17.1626 9.11259 17.1626C10.5452 17.1626 11.5911 16.376 11.5911 15.2642C11.5911 14.416 11.055 13.8535 10.1717 13.7261V13.6426C10.8528 13.4756 11.3362 12.9702 11.3406 12.2012C11.345 11.2739 10.5628 10.4961 9.15653 10.4961C7.72831 10.4961 6.82743 11.2827 6.78349 12.4517H8.00956C8.0535 11.8848 8.44901 11.5552 9.09061 11.5552C9.71024 11.5552 10.0838 11.9155 10.0838 12.3901C10.0838 12.9087 9.67509 13.2646 9.07304 13.2646H8.28202V14.2358ZM14.9883 17.1626C16.5571 17.1626 17.5063 15.9189 17.5063 13.8228C17.5063 11.7178 16.5439 10.4961 14.9883 10.4961C13.4282 10.4961 12.4702 11.7178 12.4702 13.8184C12.4702 15.9233 13.4194 17.1626 14.9883 17.1626ZM14.9883 16.0815C14.2632 16.0815 13.8149 15.3213 13.8149 13.8184C13.8149 12.3286 14.2675 11.5815 14.9883 11.5815C15.7133 11.5815 16.1616 12.3242 16.1616 13.8184C16.1616 15.3213 15.7177 16.0815 14.9883 16.0815Z"
        fill="white"
      />
    </svg>
  );
}
