import { useQuery } from "@tanstack/react-query";
import api from "../api";
import type { TopTracksApiResponse } from "./types";

async function query(artistId: string): Promise<TopTracksApiResponse> {
  const { data } = await api.get<TopTracksApiResponse>(
    `/artist/${artistId}/top-tracks`
  );

  return data;
}

type QueryKey = ["artist-top_tracks", string | undefined];
function useArtistTopTracks(artistId: string) {
  return useQuery<TopTracksApiResponse, QueryKey>(
    ["artist-top_tracks", artistId],
    () => query(artistId),
    {
      enabled: Boolean(artistId),
    }
  );
}

export default useArtistTopTracks;
