import ExternalLink from "@components/ExternalLink";
import { useSizeMeasurer } from "@widget/components/SizeMeasurerProvider";
import React from "react";
import styled, { css } from "styled-components";

const PLAYER_WIDTH_BREAKPOINT = 415;

type LogoSize = "large" | "small";

type LogoContainerProps = {
  $size: LogoSize;
};

const LogoContainer = styled(ExternalLink)<LogoContainerProps>`
  position: absolute;
  cursor: pointer;

  ${({ $size }) => {
    if ($size === "large") {
      return css`
        bottom: ${({ theme }) => theme.spacing[5]};
        right: ${({ theme }) => theme.spacing[10]};
      `;
    }

    return css`
      bottom: ${({ theme }) => theme.spacing[15]};
      right: ${({ theme }) => theme.spacing[10]};
    `;
  }}
`;

type Props = {
  redirectUrl: string;
};

const DeezerLogo: React.FC<Props> = ({ redirectUrl }) => {
  const playerSize = useSizeMeasurer();
  let size = "large" as LogoSize;

  if (!playerSize || playerSize?.width < PLAYER_WIDTH_BREAKPOINT) {
    size = "small";
  }

  return (
    <LogoContainer
      to={redirectUrl}
      $size={size}
      data-testid="widget-player-deezer-logo"
    >
      <Icon />
    </LogoContainer>
  );
};

export default DeezerLogo;

function Icon() {
  return (
    <svg
      width="60"
      viewBox="-100 -150 800 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="deezer-link-title"
      role="img"
    >
      <title id="deezer-link-title">Link to deezer.com</title>
      <path
        d="M42.7 42.7h55c34 0 58 22.7 58 54.8 0 32.2-24 54.9-58 54.9h-55V42.7Zm42.7 80h10c10.7 0 16.4-7 16.4-25.2 0-18-5.7-25.2-16.4-25.2h-10v50.4Zm167.2 29.7h-90.3V42.7h90.3v29.6h-47.7V85h44.9v24.2h-44.9v13.5h47.7v29.7Zm100.5 0H263V42.7h90v29.6h-47.7V85h44.9v24.2h-44.9v13.5H353v29.7Zm327.7 0a354.2 354.2 0 0 0-26.4-54.7c13.6-4 21.7-12.5 21.7-25.6 0-20-18.4-29.4-47.5-29.4h-59.8v109.7h42.8V107a221 221 0 0 1 21 45.4h48.2Zm-69.2-60.7V72.3h14.8c6.2 0 9.8 3.5 9.8 9.7 0 6.3-3.6 9.7-9.8 9.7h-14.8Zm-53 60.7h-90.3V42.7h90.3v29.6h-47.7V85h44.8v24.2H511v13.5h47.7v29.7Zm-195.2-80h44a208.3 208.3 0 0 0-44.9 50.3v29.7h97v-29.7H411a255.4 255.4 0 0 1 48.5-50.4V42.7h-96.1v29.6Z"
        fill="#FDFBFF"
      />
    </svg>
  );
}
