import React from "react";
import type { WidgetTrack } from "@widget/types";
import { StreamLogContextType } from "@widget/types";
import { useParams } from "react-router-dom";
import SingletonWidget from "@widget/formats/SingletonWidget";
import { dzrLink } from "@components/ExternalLink";
import useQueryError from "@widget/hooks/useQueryError";
import { useOptions } from "@widget/queryParams";
import getUTMParams from "@widget/utils/getUTMParams";
import { useTrack } from "./useTrack";
import type { TrackApiResponse } from "./types";

type RouteParams = {
  trackId: string;
};

const TrackWidget: React.FC = () => {
  const { trackId = "" } = useParams<RouteParams>();
  const { isLoading, data, isError, error } = useTrack(trackId);
  useQueryError({ isError, error });
  const { app_id } = useOptions();
  const utm = getUTMParams({
    appId: app_id,
    contextId: trackId,
    contextType: "track",
  });

  const track = getTracklist(data, utm);

  return <SingletonWidget track={track} isLoading={isLoading} />;
};

export default TrackWidget;

export const getTracklist = (
  trackResponse?: TrackApiResponse,
  utm?: string
): WidgetTrack | undefined => {
  if (!trackResponse || !trackResponse.data?.attributes) return undefined;

  const track = trackResponse.data.attributes;

  return {
    type: "track",
    id: trackResponse.data.id,
    cover: track.image.medium,
    readable: true,
    title: track.title,
    audioUrl: "",
    contextType: StreamLogContextType.Track,
    contextId: trackResponse.data.id,
    playerMeta: {
      initial: {
        title: {
          label: track.title,
          redirectUrl: dzrLink("track", trackResponse.data.id, utm),
        },
        subtitle: {
          label: track.artistName,
          redirectUrl: dzrLink("artist", track.artistId, utm),
        },
        cover: track.image.large,
      },
      touched: {
        title: {
          label: track.title,
          redirectUrl: dzrLink("track", trackResponse.data.id, utm),
        },
        subtitle: {
          label: track.artistName,
          redirectUrl: dzrLink("artist", track.artistId, utm),
        },
        cover: track.image.large,
      },
    },
    sharingMenuMeta: {
      redirectUrl: dzrLink("track", trackResponse.data.id, utm),
    },
  };
};
