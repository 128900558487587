type TextMeta = {
  label: string;
  redirectUrl: string;
};

export type PlayerMeta = {
  title: TextMeta;
  subtitle?: TextMeta;
  cover: string;
};

export type TrackListItemMeta = {
  title: {
    label: string;
  };
  subtitle?: TextMeta;
};

export type SharingMenuMeta = {
  redirectUrl: string;
};

export type WidgetTrack = {
  type: "track" | "episode";
  id: string;
  readable: boolean;
  title: string;
  cover: string;
  audioUrl: string;
  contextType: StreamLogContextType;
  contextId: string;
  playerMeta: {
    touched: PlayerMeta;
    initial: PlayerMeta;
  };
  tracklistItemMeta?: TrackListItemMeta;
  sharingMenuMeta: SharingMenuMeta;
};

export enum ContextType {
  Album = "album",
  Playlist = "playlist",
  ArtistTopTracks = "artist/top_tracks",
  Podcast = "podcast",
  Track = "track",
  Episode = "episode",
}

/**
 * songservice and streamlog endpoints names same things differently
 * this enum is used to map the songservice naming to the streamlog expectations
 */
export enum StreamLogContextType {
  Album = "album",
  Playlist = "playlist",
  ArtistTopTracks = "artist",
  Podcast = "show",
  Track = "track",
  Episode = "episode",
}
