import ActionButton from "@widget/player/components/ActionButton";
import React from "react";
import type { WidgetTrack } from "@widget/types";
import useMutatePodcastLike from "../hooks/useMutatePodcastLike";
import LikeIcon from "./LikeIcon";
import useQueryUserLikePodcast from "../hooks/useQueryUserLikePodcast";
import useToggleLike from "../hooks/useToggleLike";

type Props = {
  track?: WidgetTrack;
  onUnloggedUserToggle: () => void;
} & React.ComponentProps<typeof ActionButton>;

const ActionLikePodcastButton: React.FC<Props> = ({
  track,
  onUnloggedUserToggle,
  ...buttonProps
}) => {
  const podcastId = track?.contextId;

  const [isLiking, setIsLiking] = useQueryUserLikePodcast(podcastId);
  const { mutate } = useMutatePodcastLike({
    onSuccess: (nextIsLiking) => {
      setIsLiking(nextIsLiking);
    },
  });

  const toggleLike = useToggleLike({
    isLiking,
    mutate,
    localToggle: setIsLiking,
    onUnloggedUserToggle,
    contentId: podcastId,
  });

  return (
    <ActionButton onClick={toggleLike} {...buttonProps}>
      <LikeIcon active={isLiking} />
      <span className="sr-only">
        {isLiking ? "Unfollow show" : "Follow show"}
      </span>
    </ActionButton>
  );
};

export default ActionLikePodcastButton;
