import { useRouter } from "next/router";
import { useLocation } from "react-router-dom";

const useUrl = () => {
  const location = useLocation();
  const router = useRouter();

  if (router.pathname === "/" || router.pathname === "/demo") {
    return location.pathname;
  }

  return router.pathname;
};

export default useUrl;
