import React from "react";
import { useTheme } from "styled-components";

type LikeIconProps = {
  active?: boolean;
};

function LikeIcon({ active }: LikeIconProps) {
  const theme = useTheme();
  if (active) {
    return (
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 18C10 18 6.52508 14.7245 1.6602 9.66236C-3.03385 4.77799 3.08675 -4.07997 9.31956 2.16957C9.5464 2.39701 9.77338 2.64447 10 2.91285C10.2266 2.64453 10.4535 2.39701 10.6803 2.16938C16.9131 -4.08685 23.0339 4.68217 18.3398 9.66236C13.4749 14.7245 10 18 10 18Z"
          fill={theme.colors.primary}
        />
      </svg>
    );
  }

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.46288L9.23594 3.558C7.7386 1.78468 6.33252 1.12078 5.20792 1.02788C4.09058 0.935584 3.08089 1.39141 2.3149 2.20758C0.741712 3.88383 0.36714 6.87371 2.38121 8.96944C4.80604 11.4926 6.88419 13.5702 8.35543 15.0163C9.04678 15.6959 9.60398 16.2359 10 16.617C10.396 16.2359 10.9532 15.6959 11.6446 15.0163C13.1151 13.5709 15.192 11.4946 17.6153 8.9731C19.6398 6.82166 19.2517 3.82926 17.6872 2.17261C16.9238 1.3642 15.9159 0.915151 14.7973 1.01348C13.6703 1.11254 12.2623 1.78367 10.7641 3.558L10 4.46288ZM10 18C10 18 6.52508 14.7245 1.6602 9.66236C-3.03385 4.77799 3.08675 -4.07997 9.31956 2.16957C9.5464 2.39701 9.77338 2.64447 10 2.91285C10.2266 2.64453 10.4535 2.39701 10.6803 2.16938C16.9131 -4.08685 23.0339 4.68217 18.3398 9.66236C13.4749 14.7245 10 18 10 18Z"
        fill="white"
      />
    </svg>
  );
}

export default LikeIcon;
