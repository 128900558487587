import type { WidgetTrack } from "@widget/types";
import useTrackPreview from "@widget/contexts/track/useTrackPreview";
import useQueryError from "@widget/hooks/useQueryError";
import { useEffect, useRef } from "react";
import { useTrackDisabledContext } from "../contexts/TrackDisabledContext";

type UseAudioUrlProps = {
  track?: WidgetTrack;
  isPlaying: boolean;
  onNotAvailable: () => void;
};

function useAudioUrl({ track, onNotAvailable, isPlaying }: UseAudioUrlProps) {
  const {
    data: trackUrl,
    isError,
    error,
  } = useTrackPreview({
    track,
    isPlaying,
  });

  // Handles behavior if track couldn't be read for some reason
  useAudioUrlError({ track, onNotAvailable, isError, error });

  if (!track) return undefined;

  let audioUrl;

  if (track.type === "track") {
    audioUrl = trackUrl;
  } else if (track.type === "episode") {
    audioUrl = track.audioUrl;
  }

  return audioUrl;
}

export default useAudioUrl;

type UseAudioUrlErrorProps = {
  track?: WidgetTrack;
  onNotAvailable: () => void;
  error: any; // eslint-disable-line
  isError: boolean;
};

const useAudioUrlError = ({
  track,
  onNotAvailable,
  error,
  isError,
}: UseAudioUrlErrorProps) => {
  const [, setDisabledTrack] = useTrackDisabledContext();
  /**
   * We implemented a timeout to avoid skipping to quicly a track that is not
   * readable, because when there are multiple unreadable tracks after the other
   * making it instant is quite disturbing
   */
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleTrackNotReadable = () => {
    const trackId = track?.id;
    if (trackId) {
      timeoutRef.current = setTimeout(() => {
        setDisabledTrack({ [trackId]: true });
        onNotAvailable();
      }, 400);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useQueryError({
    error,
    isError,
    onNotAvailable: () => {
      handleTrackNotReadable();
    },
    onGenericError: () => {
      handleTrackNotReadable();
    },
  });
};
