import { useCallback, useEffect, useState } from "react";

const useAudioPlayerLoadingState = (player: HTMLAudioElement | null) => {
  const [isLoading, setIsLoading] = useState(true);

  const isWaiting = useCallback(() => {
    if (!isLoading) setIsLoading(true);
  }, [isLoading]);
  const isReady = useCallback(() => {
    if (isLoading) setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    const cleanListeners = () => {
      if (player) {
        player.removeEventListener("waiting", isWaiting);
        player.removeEventListener("playing", isReady);
      }
    };

    if (player) {
      cleanListeners();
      player.addEventListener("waiting", isWaiting);
      player.addEventListener("playing", isReady);
    }

    return cleanListeners;
  }, [isReady, isWaiting, player]);

  return isLoading;
};

export default useAudioPlayerLoadingState;
