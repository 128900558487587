/**
 * Returns true if user is on a mobile device, false instead
 *
 * Since there is no easy way to guess that, we used multiple checks to cover
 * most scenarios
 */
const isMobileDevice = () => {
  let isMobile = false;

  // Found here: https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device
  if (navigator && /Mobi|Android/i.test(navigator.userAgent)) {
    isMobile = true;
  }

  // Found here: https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
  if (
    (typeof window !== "undefined" &&
      typeof window.orientation !== "undefined") ||
    (navigator && navigator.userAgent.indexOf("IEMobile") !== -1)
  ) {
    isMobile = true;
  }

  return isMobile;
};

export default isMobileDevice;
